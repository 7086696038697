// Import custom Bootstrap variables
@font-face {
    font-family: 'luma-icons';
    src: url('../fonts/luma/Luma-Icons.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/luma/Luma-Icons.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/luma/Luma-Icons.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/luma/Luma-Icons.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('../fonts/luma/Luma-Icons.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/luma/Luma-Icons.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'font-icons-newsletter';
    src: url("../WeltPixel_Newsletter/css/fonts/font-icons.eot");
    src: url('../WeltPixel_Newsletter/css/fonts/font-icons.eot') format('embedded-opentype'),
        url('../WeltPixel_Newsletter/css/fonts/font-icons.woff') format('woff'),
        url('../WeltPixel_Newsletter/css/fonts/font-icons.ttf') format('truetype'),
        url('../WeltPixel_Newsletter/css/fonts/font-icons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Import Bootstrap for Sass
@import "variables";

@import "../web/bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../web/bower_components/font-awesome/scss/font-awesome";
@import "../web/bower_components/animate-sass/animate";
@import "../web/bower_components/slick-carousel/slick/slick.css";
@import "../web/bower_components/slick-carousel/slick/slick-theme.scss";

@import "bootstrap/responsive-utilities";
@import "buttons";

@import "global";
@import "homepage";
@import "sizes";
@import "popups";
@import "cart";
@import "modals";
@import "checkout";
@import "categories";
@import "product";
@import "minicart";
@import "account";
@import "cms";
@import "pages/homepage";
@import "pages/brands";
@import "pages/blog";
@import "pages/tradesignup";
@import "pages/compare";
@import "sections/megamenu";
@import "sections/wordpress";
@import "sections/navbar";
@import "module/sales";
@import "sections/collapsible_navigation";



// Override app/design/frontend/Yoma/gridify/web/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss:10
.container {
    width: 100%;
    @include container-fixed;

    @media (min-width: $screen-sm + 1px) {
      width: $container-sm;
    }
    @media (min-width: $screen-md + 1px) {
      width: $container-md;
    }
    @media (min-width: $screen-lg + 1px) {
      width: $container-lg;
    }
}


.nopadding {
    padding: 0;
}

.mega-dropdown-menu {
    padding-bottom: 10px !important;
}

.mega-dropdown-menu .view-all-container a {

    border: none;
    background: #28bdb7 !important;
    color: #fff;

}

.attribute.overview {
    margin-bottom: 0px;
    display: block;
    overflow: hidden;
}

.iomegamenu .navbar-nav>li.mini-menu:hover>.dropdown-menu {
    width: auto;
    left: auto;
    min-width: 230px;
}

.fotorama__caption {
    display: none;
}

.swatch-select {
    @extend .form-control;
}

.modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    -o-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
}

.catalog-product-view .product.media.col-xs-12.col-md-6 {
    margin-top: 0;
}

#product-attribute-specs-table .col.label,
#product-attribute-specs-table .col.data {
    margin: 0;
    font-size: 13px;
    border: none;
    padding: 8px 0;
}

#magenest_sagepay_cc_type_exp_div .fields.group.group-2>div {
    display: inline-block;
}

#magenest_sagepay_cc_cid {
    max-width: 70px;
}

#search_autocomplete {
    display: none !important;
}

.breadcrumbs {
    padding: 8px 0px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #fff;
    margin-top: 10px;
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Oswald', sans-serif;
    font-size: 12px;
    display: block;

    .items {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .items>li {
        display: inline-block;

        +li:before {
            content: "/ ";
            padding: 0 5px;
            color: #ccc;
        }

        strong {
            font-weight: normal;
        }
    }

    >.active {
        color: $breadcrumb-active-color;
    }

    @media (max-width: 768px) {
        display: none;
    }
}

.menulist {
    width: 100%;
}

.hide-header {
    padding-top: 47px;

    .menuheading {
        display: none;
    }
}

.price-label {
    display: none;
}

.product-items .minimal-price-link {
    display: none;
}

.slideshow-main {
    .slick-dots {
        position: absolute;
        bottom: 20px;

        li {
            button {
                border: 4px solid #fff;
                border-radius: 100%;
                width: 19px;
                height: 19px;

                &::before {
                    font-size: 14px;
                    width: 21px;
                    height: 25px;
                    color: #fff;
                    top: 1px;
                    left: -1px;
                    opacity: 1;
                    margin: 0;
                    border-radius: 100%;
                }
            }
        }
    }
}

.post-content h2 {
    font-family: 'Oswald', san-serif;
    color: #353535;
    font-size: 30px;
    margin: 40px 0px 25px;
    line-height: 140%;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #d6d6d4;
}

.post-content h3 {
    font-family: 'Oswald', san-serif;
    font-size: 18px;
    color: #28bdb7;
}

.carousel-primary-1 .slick-prev::before,
.carousel-primary-1 .slick-next::before {
    content: "" !important;
}

.wordpress-term-view {
    .page-title {
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        color: #000;
        margin-top: 0px;
        margin-bottom: 30px;
    }
}

._required>.label>span::after {
    content: "*";
    margin-left: 3px;
    color: red;
    vertical-align: top;
}

.wishlist-index-index {
    .wishlist-item {
        .wishlist-item-inner {
            border: 1px solid #d6d6d4;

            .product-item-actions {
                .btn {
                    padding: 10px 30.6px;
                }
            }

            .comment-box {
                display: none;
            }
        }

        .product-image-wrapper {
            display: block;
            margin-bottom: 20px;
            border-bottom: 1px solid #d6d6d4;
        }
    }
}

.footer-currencies {
    position: fixed;
    right: 0;
    top: 40%;
    z-index: 99;
    background: none;
    border: none;

    .label.switcher-label {
        height: 239px;
        width: 142.15px;
        display: block;
        clear: both;
        transform: rotate(-90deg);
        overflow: hidden;
        position: absolute;
        margin: auto;
        bottom: -48px;
        font-family: 'Oswald', san-serif;
        font-size: 18px;
        padding-left: 10px;
        background: #28bdb7;
        color: #fff;
        padding-top: 13px;
    }

    .currency-container {
        width: 40px;
        height: 143px;
        display: table;
        position: relative;
        z-index: 9;
    }

    .currency-container,
    .label.switcher-label {
        left: 40px;
        cursor: pointer;
        transition: left 0.5s linear;
    }

    &.active {

        .currency-container,
        .label.switcher-label {
            left: 0 !important;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            float: none;
            list-style: none;
            padding: 10px;
            font-family: 'Oswald', san-serif;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 18px;
            width: 40px;
            text-align: center;
            background: #fff;
            color: #000;
            margin: 0;
            border: 1px solid #333;

            a {
                color: #333
            }

            &.current {
                background-color: #555555;
                color: #fff;
                border: 1px solid #333;
            }
        }
    }
}


.si-facebook:hover,
.si-colored.si-facebook {
    background-color: #3b5998 !important
}

.si-pinterest:hover,
.si-colored.si-pinterest {
    background-color: #c8232c !important
}

.si-vimeo:hover,
.si-colored.si-vimeo {
    background-color: #86c9ef !important
}

.si-instagram:hover,
.si-colored.si-instagram {
    background-color: #3f729b !important
}

.si-twitter:hover,
.si-colored.si-twitter {
    background-color: #00acee !important
}

[class^="icon-"],
[class*=" icon-"],
[class^="icon-line-"],
[class*=" icon-line-"],
[class^="icon-line2-"],
[class*=" icon-line2-"] {
    display: inline-block;
    font-family: 'font-icons-newsletter';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
    content: "\e86f";
}

.icon-twitter:before {
    content: "\e85f";
}

.icon-vimeo:before {
    content: "\e861";
}

.icon-instagram:before {
    content: "\e889";
}

.icon-pinterest:before {
    content: "\e83e";
}

.social-icons {
    display: inline-block;
    text-align: center !important;
    cursor: pointer;
    font-style: normal;
    margin: 0 5px 5px 0;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 38px !important;
    color: #555;
    text-shadow: none;
    border: 1px solid #AAA;
    border-radius: 3px;
    overflow: hidden;
}

a.social-icons,
a.social-icons:active,
a.social-icons:visited,
a.social-icons:hover {
    text-decoration: none;
}

body:not(.device-touch) .social-icons {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.si-rounded {
    border-radius: 50%;
}


.si-dark {
    background-color: #444;
    color: #FFF !important;
    border-color: transparent;
}

.social-icons i {
    display: block;
    position: relative;
}

body:not(.device-touch) .social-icons i {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.social-icons:hover i:first-child {
    margin-top: -38px;
}

/** Social Icons css */
.related-products {
    overflow: visible !important;
    padding-top: 20px;

    .products-related {

        .slick-prev::before,
        .slick-next::before {
            color: #28bdb7 !important;
            opacity: 1;
            height: auto;
            z-index: 9999;
            font-size: 28px;
        }
    }
}

#wpn-lightbox-overlay {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

#wpn-lightbox-content {
    display: none;
    position: absolute;
    z-index: 10000;
    top: 0;
    max-width: 850px;
    width: 740px;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#wpn-lightbox-close-newsletter {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 5px;
    font-weight: 400;
    width: 30px;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    background: #000;
    color: #fff;
    vertical-align: middle;
    cursor: pointer;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
}

.countdown {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Oswald;
    font-weight: 600;
    text-transform: uppercase;
}

.countdown__dispatch_date,
.countdown__delivery_day {

    color: #4DCCCC;

}

.weltpixel_newsletter {
    #weltpixel_newsletter .terms-condition-container label {
        display: inline-block;
        width: 93%;
        font-size: 11px;
        padding-top: 4px;
        margin-left: 5px;
        font-family: 'Oswald', sans-serif;
        font-weight: normal;
        font-weight: 600;
    }

    .wpn-smalldesc {
        max-width: 70%;
        margin: 20px auto;
        display: block;
        font-family: 'Oswald', san-serif;
        font-size: 19px;
        line-height: 24px;
    }

    button.primary:hover,
    button.primary {
        background-color: #28bdb7 !important;
        max-width: 70%;
        margin: 30px auto 0;
        padding-top: 15px;
        padding-bottom: 15px;
        @extend .btn;
        @extend .btn-lg;
        @extend .btn-block;
        @extend .btn-primary;
    }

    .wpn-bottom {
        display: block;
        width: 90%;
        margin: auto;
        padding: 30px 0;
        font-size: 12px;
        text-align: center;
        font-family: 'Oswald', san-serif;
    }

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .wpn-col-md-5 {
        line-height: 10px;
    }

    .weltpixel_newsletter_signup_section {
        padding: 35px;
        text-align: center;

        strong {
            font-size: 12px;
        }

        .title {
            font-size: 48px;
            font-weight: 600;
            font-family: 'Oswald', sans-serif;
            color: #000;
        }
    }

    #weltpixel_newsletter {
        padding: 15px 0;
        width: 100%;
        max-width: 490px;
        margin: 0 auto;
        display: block;
        float: none;

        .form-group {
            position: relative;
        }

        .title {
            font-size: 26px;
            text-transform: uppercase;
            text-align: center;
        }

        input[type=text],
        input[type=email],
        #popup_newsletter_email {
            width: 100%;
            height: 36px;
            background: #ffffff;
            padding: 23px;
            font-size: 12px;
            float: none;
            line-height: 30px;
            text-transform: uppercase;
            text-align: left;
            display: block;
            margin: auto;
            border: 2px solid #000;
            margin-bottom: 20px;
        }

        #terms_conditions_consent {
            width: 17px;
            height: 17px;
        }

        .button {
            height: 36px;
            padding: 8px;
            margin-left: 0px;
            float: right;
            font-size: 18px;
            line-height: 22px;
            background: #d83701;
            color: #FFF;
            text-transform: uppercase;
        }

        .terms-condition-container {
            position: relative;
            margin-top: 18px;
            text-align: left;

            label {

                &,
                &>* {
                    vertical-align: top;
                }
            }
        }

        #popup_newsletter_email-error,
        #terms_conditions_consent-error {
            clear: both;
            min-height: 13px;
            margin: 3px 0 0;
            font-size: 14px;
            font-weight: bold;
            line-height: 13px;
            text-align: left;
            position: absolute;
            top: 35px;
        }

        #terms_conditions_consent-error {
            top: 0px;
            position: relative;
        }
    }
}

@media only screen and (max-width: 960px) {
    .weltpixel_newsletter {
        .weltpixel_newsletter_signup_section {

            #weltpixel_newsletter input[type=text],
            input[type=email],
            #popup_newsletter_email {
                width: 65%;
            }
        }
    }
}

@media (min-width: 992px) {
    .weltpixel_newsletter {

        .wpn-col-md-5,
        .wpn-col-md-7 {
            float: left
        }

        .wpn-col-md-5 {
            width: 41.66666667%;
        }

        .wpn-col-md-7 {
            width: 58.33333333%;
        }
    }
}

@media screen and (max-width: 992px) {
    #wpn-lightbox-content {
        width: 88%;
        padding: 6%;
    }
}

@media only screen and (max-width: 768px) {
    .weltpixel_newsletter {
        .weltpixel_newsletter_signup_section {
            width: 100%;
            padding: 0;

            #weltpixel_newsletter {
                padding: 15px 0;
                width: 100%;
                float: left;

                .button {
                    float: right;
                }

                input[type=text],
                input[type=email],
                #popup_newsletter_email {
                    width: 75%;
                    float: left;
                }
            }
        }

        .col-last {
            display: none;
        }
    }
}

@media only screen and (max-width: 480px) {
    .weltpixel_newsletter {
        .weltpixel_newsletter_signup_section {
            #weltpixel_newsletter {

                input[type=text],
                input[type=email],
                #popup_newsletter_email {
                    width: 60%;
                }
            }
        }
    }
}

.wishlist-index-index strong.product-item-name {
    height: 66px;
    display: block;
    overflow: hidden;
}

.wishlist-index-index .product-items .product-item {
    margin-bottom: 10px;
}

.google-map {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.contact-wrapper {
    margin-bottom: 40px;
}

button.primary.submit {
    margin-top: 20px
}

.sales-order-print {
    .block-order-details-view {
        .block-content {
            display: flex;
            flex-wrap: wrap;

            .box {
                flex: 1 0 50%;
            }
        }
    }

    .product-item-name {
        font-weight: normal;
    }

    .table > thead > tr > th {
        &.col.name {
            white-space: normal;
            width: auto;

            @media print {
                width: 250px;
            }
        }
    }

    .table {
        &>thead,
        &>tbody {
            & > tr {
                th,
                td {
                    &.empty {
                        @media print {
                            padding: 0px;
                            width: 0px;
                        }
                    }
                }
            }
        }
    }

    .order-details-items.ordered {
        .order-title {
            display: none;
        }
    }

    .page-title-wrapper {
        .order-date {
            display: flex;
            align-content: center;
            justify-content: flex-start;
            align-items: flex-end;

            .label {
                margin-right: 10px;
            }
        }
    }

    .page-header-after,
    .page-footer-before {
        display: none;
    }

    .col.price .price, 
    .col.subtotal .price {
        line-height: normal;
    }
}

@import "responsive/tablet";

@import "responsive/mobile";
