@media screen and (min-width: 993px) {
    .navbar-nav {
        float: none;
        display: flex;
        width: 100%;
        align-items: center;
        height: 100%;

        & > li {
            float: none;
        }
    }
}

@media screen and (max-width: 992px) {
    button.navbar-toggle {
        background: #007096;
        border-radius: 0;
        color: #fff;
        margin: 0;
        margin-right: 10px;
        height: 45px;

        .icon-bar {
            background-color: #fff !important;
        }
    }
}
