.order-details-items {
    border: 1px solid #cccccc;
    margin-bottom: 40px;

    .items-qty {
        margin: 0;
        padding: 0;
        list-style: none none;
        display: inline-block;
        vertical-align: top;

        &>li {
            margin: 0;
        }

        .item {
            white-space: nowrap;
        }

        .title {
            &:after {
                content: ': ';
            }
        }
    }

    .col {
        &.price,
        &.subtotal {
            .price {
                &-including-tax,
                &-excluding-tax {
                    display: block;
                    font-size: 1.8rem;
                    line-height: 1;

                    .price {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .order-title {
        border-bottom: 1px solid #cccccc;
        padding: 0 0 10px;

        >strong {
            font-size: 2.4rem;
            font-weight: 300;
        }

        .action {
            display: inline-block;
            margin: 12px 0 0 30px;
        }

        strong {
            display: block;
        }

        .action.track {
            float: right;
        }
    }

    .table-wrapper {
        margin: 0;
        border-top: 0;

        + {
            .actions-toolbar {
                display: none;
            }
        }

        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .table-wrapper.order-items-shipment {
        overflow: visible;
    }

    .table-order-items {
        >thead {
            >tr {
                >th {
                    border-bottom: 1px solid #cccccc;
                }
            }
        }

        tbody {
            &+tbody {
                border-top: 1px solid #cccccc;
            }

            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }

            .col.label {
                font-weight: 700;
                padding-bottom: 5px;
                padding-top: 0;
            }

            .col.options {
                padding: 10px 10px 15px;
            }

            >.item-parent {
                td {
                    padding-bottom: 5px;
                }

                &+tr {
                    td {
                        padding-top: 5px;
                    }
                }
            }

            .item-options-container {
                td {
                    padding-bottom: 15px;
                    padding-top: 0;
                }
            }
        }

        .product-item-name {
            margin: 0 0 10px;
        }

        .options-label {
            display: none;
        }
    }

    .item-options {
        margin: 0;

        dt {
            margin: 0;
        }

        dd {
            margin: 0 0 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .item-options.links {
        dt {
            display: inline-block;

            &:after {
                content: ': ';
            }
        }

        dd {
            margin: 0;
        }
    }

    thead {
        .col.qty {
            text-align: center;
        }
    }

    .col.name {
        padding-top: 16px;
    }

    .action.show {
        display: inline-block;
        text-decoration: none;
        color: #333333;

        >span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:after {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 10px;
            line-height: 10px;
            color: inherit;
            content: '\e622';
            font-family: 'luma-icons';
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }
    }

    .action.show.expanded {
        &:after {
            content: '\e621';
        }
    }

    .product-item-name {
        font-size: 1.8rem;
    }

    .pages-items {
        .item {
            &:first-child {
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        margin-top: 10px;
    }

    .data.table {
        .col.options {
            padding: 0 10px 15px;

            &:before {
                display: none;
            }
        }
    }

    .options-label,
    .item-options-container {
        &+.item-options-container {
            &[data-th]:before {
                content: attr(data-th) ':';
                display: block;
                font-weight: 700;
                padding-left: 10px;
            }

            .col {
                font-size: 1.2rem;
                padding: 0 10px;

                &:first-child {
                    padding-top: 3px;
                }

                &:last-child {
                    padding-bottom: 20px;
                }
            }
        }
    }
}

.cart.table-wrapper {
    .col.msrp {
        .price-excluding-tax {
            .price {
                font-weight: 700;
            }
        }
    }
}

.magento-rma-guest-returns {
    .column.main {
        .block {
            &:not(.widget) {
                .block-title {
                    >.action {
                        margin-left: 15px;
                    }
                }

                .box-title {
                    display: inline-block;
                    margin: 0 0 10px;

                    >span {
                        font-size: 1.6rem;
                    }

                    >.action {
                        font-weight: 400;
                        margin-left: 10px;
                    }
                }
            }
        }

        .order-details-items {
            .table-wrapper {
                .data.table {
                    border: none;

                    >tbody {
                        >tr {
                            &:nth-child(even) {

                                >td,
                                >th {
                                    background: #f6f6f6;
                                }
                            }

                            >th,
                            >td {
                                border: none;
                            }
                        }
                    }

                    >thead {
                        >tr {

                            >th,
                            >td {
                                border: none;
                                border-bottom: 1px solid #cccccc;
                            }
                        }
                    }

                    >tfoot {
                        >tr {

                            >th,
                            >td {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-main {
        .page-title-wrapper {
            .page-title {
                margin-right: 25px;
            }

            .order-date {
                font-size: 16px;
                margin-bottom: 10px;

                .label {
                    display: none;
                }
            }
        }
    }
}

[class^='sales-guest-'],
.sales-guest-view {
    .column.main {
        .block {
            &:not(.widget) {
                .block-title {
                    >.action {
                        margin-left: 15px;
                    }
                }

                .box-title {
                    display: inline-block;
                    margin: 0 0 10px;

                    >span {
                        font-size: 1.6rem;
                    }

                    >.action {
                        font-weight: 400;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .page-main {
        .page-title-wrapper {
            .page-title {
                margin-right: 25px;
                display: block;
            }

            .order-date {
                font-size: 16px;
                margin-bottom: 10px;

                .label {
                    display: none;
                }
            }

            .order-status {
                margin: -10px 0 10px;
            }
        }
    }
}

.return-status {
    border: 2px solid #cccccc;
    border-radius: 3px;
    display: inline-block;
    margin: 3px 0 0;
    padding: 2px 10px;
    text-transform: uppercase;
    vertical-align: top;
}

.order-status {
    border: 2px solid #cccccc;
    border-radius: 3px;
    display: inline-block;
    margin: 3px 0 0;
    padding: 2px 10px;
    text-transform: uppercase;
    vertical-align: top;
}

.account {
    .page-main {
        .page-title-wrapper {
            .page-title {
                margin-right: 25px;
                display: block;
            }

            .order-date {
                font-size: 16px;
                margin-bottom: 10px;

                .label {
                    display: none;
                }
            }

            .order-status {
                margin: -10px 0 10px;
            }
        }
    }

    .order-details-items {
        .table-order-items {
            tbody {
                tr {
                    &:nth-child(even) {
                        td {
                            background: none;
                        }
                    }
                }
            }
        }
    }

    .table-order-items {
        .col.actions {
            &:before {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }

        .col.options {
            &:before {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }

        .product-item-name {
            display: inline-block;
            margin: 0;
        }

        .action.show {
            margin-top: 0;
        }
    }
}

.block-reorder {
    .block-title {
        margin: 0 0 20px;

        strong {
            font-size: 18px;
            font-weight: 300;
            vertical-align: middle;
        }
    }

    .actions-toolbar {
        margin: 17px 0;
    }

    .product-item-name {
        float: left;
        width: calc(80%);
    }

    .product-item {
        &::after {
            clear: both;
            content: '';
            display: table;
        }

        .field.item {
            float: left;
            width: 20px;
        }
    }
}

.order-links {
    .item {
        line-height: 40px;
        margin: 0 0 -1px;

        strong {
            background: #ffffff;
            border: 1px solid #cccccc;
            display: block;
            font-weight: 400;
            padding: 0 20px;
        }

        a {
            background: #f6f6f6;
            color: #6d6d6d;
            border: 1px solid #cccccc;
            display: block;
            font-weight: 400;
            padding: 0 20px;

            &:hover {
                background: #ffffff;
                text-decoration: none;
            }
        }
    }
}

.block-order-details-comments {
    margin: 0 0 40px;

    .comment-date {
        font-weight: 600;
    }

    .comment-content {
        line-height: 1.6;
        margin: 0 0 20px;
    }
}

.block-order-details-view {
    .block-content {
        .box {
            margin-bottom: 30px;
        }
    }

    .box-title {
        font-weight: 600;
    }

    .box-content {
        .payment-method {
            .title {
                font-weight: 400;
            }

            .content {
                margin: 0;

                >strong {
                    font-weight: 400;

                    &:after {
                        content: ': ';
                    }
                }
            }
        }
    }
}

.column.main {
    .order-details-items {
        .block-order-details-gift-message {
            margin: 40px 0 0;
        }
    }
}

.block-order-details-gift-message {
    &+.actions-toolbar {
        display: none;
    }
}

.order-tracking {
    margin: 0;
    padding: 20px 0;

    .tracking-title {
        display: inline-block;
    }

    .tracking-content {
        display: inline-block;
        margin: 0 0 0 5px;
    }
}

.order-title {
    &+.order-tracking {
        border-bottom: 1px solid #cccccc;
    }
}

.order-actions-toolbar {
    margin-bottom: 25px;

    .action {
        margin-right: 30px;
    }

    .action.print {
        display: none;
        margin: 0;
    }
}

.form-orders-search {
    .field {
        &:last-child {
            margin-bottom: 20px;
        }
    }
}

.order-pager-wrapper {
    .toolbar-amount {
        left: inherit;
        position: relative;
        text-align: center;
        top: inherit;
    }

    .pages {
        text-align: center;
    }

    .action.previous {
        margin: 0;
    }

    .action.next {
        margin: 0;
    }

    display: block;
}

.table-wrapper.reward-history {
    margin-top: -20px;
}

.table-wrapper.orders-recent {
    margin-top: -20px;
    border-top: 0;

    .table-order-items.table {
        tbody {
            >tr {
                >td.col {
                    padding-left: 0;
                }
            }
        }
    }
}

.abs-title-orders-mobile {
    .page-main {
        .page-title-wrapper {
            .order-status {
                margin: -10px 0 10px;
            }
        }
    }
}

.magento-rma-returns-returns {
    .page-main {
        .page-title-wrapper {
            .order-status {
                margin: -10px 0 10px;
            }
        }
    }
}

.magento-rma-returns-view {
    .page-main {
        .page-title-wrapper {
            .order-status {
                margin: -10px 0 10px;
            }
        }
    }
}

.table-wrapper.table-returns {
    border-top: 0;
}

.table > tbody > tr > td {
    vertical-align: middle;
}

@media all and (max-width: 992px) {
    .order-links {
        display: none;
    }

    .order-status {
        padding: 0px 5px;
        margin-top: 0px;
    }

    .order-details-items {
        border: 0px;
    }
}

@media all and (min-width: 993px),
print {
    .order-links {
        &:before {
            content: '';
            display: table;
        }

        &:after {
            content: '';
            display: table;
            clear: both;
        }

        .item {
            float: left;
            margin: 0 -1px 0 0;

            a {
                padding: 1px 20px;
            }

            strong {
                border-bottom: 0;
                margin-bottom: -1px;
                padding: 1px 20px 2px 20px;
            }
        }
    }

    .account,
    [class^='sales-guest-'],
    .sales-guest-view {
        .column.main {
            .block.block-order-details-view {
                &:before {
                    content: '';
                    display: table;
                }

                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }

                .block-content {
                    &:not(.widget) {
                        .box {
                            box-sizing: border-box;
                            clear: none;
                            float: left;
                            width: 25%;
                        }
                    }
                }
            }
        }

        .page-main {
            .page-title-wrapper {
                .order-date {
                    margin: -14px 0 18px;
                }
            }
        }
    }

    .page-header,
    .header.content {
        .header.panel {
            &:before {
                content: '';
                display: table;
            }

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }
    }

    .block-cart-failed {
        box-sizing: border-box;
    }

    .cart-container {
        .form-cart {
            box-sizing: border-box;
        }

        .cart-gift-item {
            box-sizing: border-box;
        }
    }

    .table-wrapper {

        &.reward-history,
        &.orders-recent {
            margin-top: -25px;
        }
    }

    .return-status,
    .order-status {
        margin-top: 6px;
        padding: 5px 10px;
    }

    .abs-title-orders-desktop,
    .magento-rma-returns-returns,
    .magento-rma-returns-view {
        .page-main {
            .page-title-wrapper {
                .order-date {
                    margin: -14px 0 18px;
                }
            }
        }
    }

    .order-actions-toolbar {
        .action.print {
            display: block;
            float: right;
        }
    }

    .block-order-details-comments {
        margin: 0 0 60px;

        .comment-date {
            clear: left;
            float: left;
            margin-right: 50px;
            max-width: 90px;
        }

        .comment-content {
            overflow: hidden;
        }
    }

    .order-details-items {
        margin-top: -1px;
        padding: 25px;

        .col.price {
            text-align: center;
        }

        .col.subtotal {
            text-align: right;
        }

        tbody {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }

        tfoot {
            .amount {
                text-align: right;
            }

            .mark {
                text-align: right;
            }
        }
    }

    .order-details-items.ordered {
        .order-title {
            display: none;
        }
    }

    .order-pager-wrapper {
        .order-pager-wrapper-top {
            padding-left: 0;
            padding-right: 0;
        }

        .toolbar-amount {
            position: relative;
        }

        .pages {
            float: right;
        }
    }

    .table-order-items {
        tbody {
            .col.label {
                padding-left: 0;
            }

            .col.value {
                padding-left: 0;
            }
        }
    }

    .table-order-items {

        &.invoice,
        &.shipment {
            .col.qty {
                text-align: center;
            }
        }

        &.creditmemo {
            .col.qty {
                text-align: center;
            }

            .col.discount {
                text-align: center;
            }

            .col.subtotal {
                text-align: center;
            }

            .col.total {
                text-align: right;
            }
        }
    }
}

@media all and (min-width: 1024px),
print {
    .order-links {
        .item {
            margin: 0 -1px 0 0;

            a {
                padding: 1px 35px;
            }

            strong {
                padding: 1px 35px 2px 35px;
            }
        }
    }
}
