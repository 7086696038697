.is-blog {

    .block-blog-categories .block-title,
    .block-blog-archives .block-title {
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;

        strong {
            font-size: 18px;
            font-weight: 500;
            color: #333;
        }
    }

    .page-title {
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        color: #000;
        margin-bottom: 30px;
    }

    .block-blog-archives {
        margin-top: 20px;
    }

    .post-list-wrapper {
        .post-list>[class*="col-"] {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
        }

        .post-list {
            display: flex;
            flex-wrap: wrap;

            .item {
                margin-bottom: 30px;

                h2 {
                    font-size: 13px;
                    display: block;
                    margin-top: 20px;
                    overflow: hidden;
                    clear: both;
                    color: #002f42;
                    font-family: 'Oswald';
                    font-weight: 600;
                    min-height: 40px;
                    margin-bottom: 10px;

                    a {
                        color: #002f42;
                        font-family: 'Oswald';
                        font-weight: 600;
                    }
                }

                .post-excerpt {
                    font-family: "Oswald";
                    font-size: 11px;
                    min-height: 80px;
                    max-height: 80px;
                }

                .post-date {
                    font-size: 11px;
                    background: #5c6f7b;
                    display: table;
                    color: #fff;
                    padding: 5px 13px;
                    border-radius: 5px;
                }

                .post-image {
                    float: none;
                    margin-right: 10px;
                }

                img {
                    max-width: 100%;
                    max-height: 230px;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .is-blog {
        .post-list-wrapper {
            .post-list {
                display: block;
                flex-wrap: unset;
            }
        }
    }
}

.block-recent-posts {
    .block-title {
        border-bottom-color: #dddddd;
        margin-bottom: 20px;

        strong {
            font-size: 20px !important;
            color: #002f42;
            font-family: "Oswald";
            font-weight: bold !important;
            margin-bottom: 20px;
        }
    }

    .block-content {
        margin-top: 10px;

        .item {
            margin-bottom: 30px;

            .title {
                color: #002f42;
                font-family: 'Oswald';
                font-weight: 600;
                font-size: 14px;
                max-width: 230px;
                display: block;
                margin-bottom: 10px;
            }

            .post-date {
                font-size: 11px;
                background: #5c6f7b;
                display: table;
                color: #fff;
                padding: 5px 13px;
                border-radius: 5px;
            }
        }
    }
}

.wordpress-post-view {
    .post-view {
        font-family: 'Oswald', sans-serif;
        font-size: 14px;
        line-height: 21px;
        color: #333333;

        p {
            margin-bottom: 10px;
        }

        border-bottom:1px solid #ccc;
    }

    .page-footer {
        margin-top: 0;
    }

    .blogview-section-two {
        padding-top: 70px;
        background: #f6f8fa;
        margin-top: 40px;
        padding-bottom: 70px;
    }

    .customer-quick-access {
        padding: 30px 60px;
        background: #fff;
        border: 1px solid #dddddd;
        display: flex;
        flex-wrap: wrap;

        [class*="col-"] {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .col-md-6+.col-md-6 {
            border-left: 1px solid #dddddd;
            padding-left: 90px;
        }

        .title {
            color: #002f42;
            font-weight: 700;
            margin-bottom: 30px;
            font-size: 24px;
            display: block;
            line-height: 41px;
        }

        .content {
            min-height: 70px;
            max-width: 70%;
            margin-bottom: 20px;
            display: block;
        }
    }

    .post-share-container {
        margin: 30px 0;

        strong {
            font-size: 14px;
        }

        .post-share {
            list-style: none;
            padding: 0;
            margin: 5px 0 20px;
            font-size: 40px;

            li {
                display: inline-block;
            }

            .twitter {
                a {
                    color: #1da1f5;
                }
            }

            .facebook {
                a {
                    color: #3b5998;
                }
            }

            .linkedin {
                a {
                    color: #0077b5;
                }
            }

            .email {
                a {
                    color: #c3d600;
                }
            }
        }
    }

    @media (max-width: 595px) {
        .page-title-wrapper {
            margin-top: 10px;
            margin-bottom: 20px;

            .tite-outer-span span {
                padding-right: 0 !important;
                background: #fff !important;
                font-size: 24px;
                width: 90% !important;
                display: block;
            }
        }

        .post-view {
            max-width: 100%;
        }

        .post-entry {
            overflow: hidden;
            margin: auto;
            width: 100%;
        }

        img.alignleft,
        img.alignright {
            float: none;
            margin: 10px auto;
            max-width: 100%;
            height: auto;
        }

        .customer-quick-access {
            padding: 10px 10px 30px;

            .content {
                margin-left: auto;
                margin-right: auto;
            }

            .btn {
                font-size: 10px;
            }

            .col-md-6+.col-md-6 {
                border-left: none;
                padding-left: 15px;
                text-align: center;
            }

            .actions,
            .content,
            .title {
                text-align: center;
            }
        }

        .widget-category-block {
            img {
                max-width: 100%;
                border-radius: 0 0 5px 5px;
                width: 100%;
            }
        }
    }
}

.block-other-news {
    .post-list {
        display: flex;
        flex-wrap: wrap;
    }

    .post-list>[class*="col-"] {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .post-date {
        font-size: 11px;
        background: #5c6f7b;
        display: table;
        color: #fff;
        padding: 5px 13px;
        border-radius: 5px;
    }

    a.title {
        font-size: 16px;
        font-size: 13px;
        display: block;
        margin-top: 20px;
        overflow: hidden;
        clear: both;
        color: #002f42;
        font-family: 'Oswald';
        font-weight: 600;
        min-height: 40px;
        margin-bottom: 10px;
    }

    .post-excerpt {
        font-family: "Oswald";
        font-size: 11px;
        min-height: 80px;
        max-height: 80px;
    }

    .block-inner {
        padding: 0;
        background: #fff;
        border: none;

        img {
            max-width: 100%;
            margin: auto;
            display: block;
            max-height: 210px;
            border-radius: 6px;
        }
    }

    .block-title {
        .widget-title {
            color: #002f42;
            font-weight: 700;
            margin-bottom: 30px;
            font-size: 24px;
            display: block;
            line-height: 41px;
        }
    }

    .block-content {
        .slick-prev {
            left: 2px;
            z-index: 999;
            top: 150px;

            &::before {
                background: url('../images/arrow-l.png') no-repeat;
                font-family: unset;
                content: "";
                height: 34px;
                width: 24px;
                display: block;
                background-size: contain;
                opacity: 1;
            }
        }

        .slick-next {
            right: 2px;
            top: 150px;
            z-index: 999;

            &::before {
                background: url('../images/arrow-r.png') no-repeat;
                font-family: unset;
                content: "";
                height: 34px;
                width: 24px;
                display: block;
                background-size: contain;
                opacity: 1;
            }
        }

        img {
            max-width: 100%;
            margin: auto;
            display: block;
        }

        .actions {
            display: block;
            margin-top: 30px;
        }

        .description {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 12px;
        }

        .case-study-article {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}

.latestnews-otherarticles {
    padding: 35px 0 0px 0;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    list-style: none;

    li {
        text-align: left;

        &.hp-latest-posts {
            padding-bottom: 20px;
        }

        .post-content {
            position: relative;
            background: #005775;
            clear: both;
            /*padding: 380px 20px 20px 20px;*/
            padding: 24px 20px 20px 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            .featured-image {
                img {
                    width: 100%;
                }
            }

            a {
                text-decoration: none;

                &.post-heading {
                    font-family: 'Oswald', sans-serif;
                    color: #fff;
                    font-size: 24px;
                    text-transform: none;
                    margin-bottom: 15px;
                    margin-top: 20px;
                    line-height: 30px;
                    display: inline-block;

                    &:hover {
                        text-decoration: none;
                        color: #fff;
                    }

                    &:focus {
                        text-decoration: none;
                        color: #fff;
                    }

                    &:active {
                        text-decoration: none;
                        color: #fff;
                    }
                }
            }

            .post-date {
                position: relative;
                color: #fff;
                text-transform: uppercase;
                border-bottom: 1px solid #0e4d62;
                font-weight: 600;
                padding: 0 3px 15px 30px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                &:before {
                    position: absolute;
                    left: 0;
                    top: -2px;
                    font-family: "FontAwesome";
                    content: "\f073";
                    font-size: 16px;
                    color: #ff7500;
                }

            }

            p {
                font-family: 'Oswald', sans-serif;
                color: #fff;
                font-size: 14px;
                line-height: 20px;
                display: inline-block;
                margin-bottom: 15px;
            }

            .button {
                text-decoration: none;
                display: block;
                text-align: center;
                color: #005775;
                background: white;
                width: 120px;
                height: 35px;
                line-height: 35px;
                padding: 0;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
                transition: 0.4s;
                -webkit-transition: 0.4s;
                -moz-transition: 0.4s;

                &:hover {
                    background: #ff7400;
                    text-decoration: none;
                    color: #fff;
                }

                &:focus {
                    background: #ff7400;
                    text-decoration: none;
                    color: #fff;
                }
            }

        }
    }
}




.latestnews-posts {
    padding: 30px 0;

    .section-title {
        text-align: left;

        h2 {
            text-transform: uppercase;
            color: #005775;
            font-size: 28px;
            font-weight: 600;
            font-family: 'Oswald', sans-serif;
        }
    }

    .readmore {
        text-align: right;
        padding-top: 18px;

        .button {
            float: right;
            display: block;
            text-align: center;
            color: #fff;
            background: #007096;
            width: 120px;
            height: 35px;
            line-height: 35px;
            padding: 0;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            transition: 0.4s;
            -webkit-transition: 0.4s;
            -moz-transition: 0.4s;
            text-decoration: none;

            &:hover {
                background: #ff7400;
                color: #fff;
                text-decoration: none;
            }

            &:focus {
                background: #ff7400;
                color: #fff;
                text-decoration: none;
            }
        }
    }

    .latestnews-list {
        clear: both;
        display: block;
        padding: 35px 0 20px 0;
        text-align: center;
        list-style: none;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0px;
            height: 2px;
            left: 15px;
            right: 15px;
            width: calc(100% - 30px);
            background-color: #cce2ea;
        } 

        li {
            text-align: left;

            @media (max-width: 768px) {
                margin: 0 auto 30px auto;
            }

            .post-content {
                position: relative;
                background: #005775;
                clear: both;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                height: 100%;

                .featured-image {
                    width: 100%;

                    img {
                        height: auto;
                        max-height: 250px;
                        max-width: 100%;
                        object-fit: cover;
                        display: block;
                        width: 100%;

                        @media screen and (max-width: 991px){
                            max-height: 210px;
                        }
                    }
                }

                .post-content-container {
                    padding: 24px 20px 20px 20px;
                }

                a {
                    text-decoration: none;
                    display: block;
                    width: 100%;
                    text-align: center;

                    &.post-heading {
                        font-family: 'Oswald', sans-serif;
                        color: #fff;
                        font-size: 24px;
                        text-transform: none;
                        margin-bottom: 15px;
                        margin-top: 20px;
                        line-height: 30px;
                        display: inline-block;

                        @media (max-width: 1199px) and (min-width: 992px) {
                            min-height: 90px;
                        }

                        @media (max-width: 991px) {
                            min-height: 70px;
                            font-size: 18px;
                            margin-top: 0px;
                            line-height: 24px;
                        }

                        &:hover {
                            text-decoration: none;
                            color: #fff;
                        }

                        &:focus {
                            text-decoration: none;
                            color: #fff;
                        }

                        &:active {
                            text-decoration: none;
                            color: #fff;
                        }
                    }
                }

                .post-date {
                    position: relative;
                    color: #fff;
                    text-transform: uppercase;
                    border-bottom: 1px solid #0e4d62;
                    font-weight: 600;
                    padding: 0 3px 15px 30px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    &:before {
                        position: absolute;
                        left: 0;
                        top: -2px;
                        font-family: "FontAwesome";
                        content: "\f073";
                        font-size: 16px;
                        color: #ff7500;
                    }

                    @media screen and (max-width: 991px){
                        font-size: 12px;
                    }
                }

                .post-excerpt {
                    font-family: 'Oswald', sans-serif;
                    color: #fff;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 15px;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    min-height: 80px;
                }

                .button {
                    text-decoration: none;
                    display: block;
                    text-align: center;
                    color: #005775;
                    background: white;
                    width: 120px;
                    height: 35px;
                    line-height: 35px;
                    padding: 0;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border-radius: 4px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    transition: 0.4s;
                    -webkit-transition: 0.4s;
                    -moz-transition: 0.4s;

                    &:hover {
                        background: #ff7400;
                        text-decoration: none;
                        color: #fff;
                    }

                    &:focus {
                        background: #ff7400;
                        text-decoration: none;
                        color: #fff;
                    }
                }

            }
        }
    }
}



.brands-brand-view {
    .post-view {
        border-top: none !important;
        border-bottom: none !important;
    }
}

.post-content {

    h2,
    h3,
    h4,
    h5 {
        font-family: 'Oswald', san-serif;
    }
}
