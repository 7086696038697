.checkout-container {
    margin: 0 0 20px;
}

.opc-wrapper {
    margin: 0 0 20px;

    .agreements-modal {
        display: none;
    }

    .modal-slide {
        bottom: 0;
        left: 0;
        min-width: 0;
        position: fixed;
        right: 0;
        top: 0;
        visibility: hidden;
    }

    .step-content {
        margin: 0 0 40px;
    }

    .field {
        .control._with-tooltip {
            input {
                margin-right: 10px;
                width: calc(100% - 36px);
                background: #fff;
                background-clip: padding-box;
                border: 1px solid #c2c2c2;
                border-radius: 1px;
                font-family: 'Oswald', sans-serif;
                font-size: 14px;
                height: 32px;
                line-height: 1.42857143;
                padding: 0 9px;
                vertical-align: baseline;
                box-sizing: border-box;
            }
        }
    }

    .label {
        font-size: 15px;
        font-weight: bold;
        line-height: 1;
        color: #000;
        text-align: left;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25em;
        display: block;
        margin-top: 10px;
        padding-left: 0;
    }

    legend {
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 0;
        font-size: 21px;
        line-height: inherit;
        color: #333333;
        border: 0;
        border-bottom: none;
    }

    .step-title {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        color: #333;
        font-weight: 300;
        font-size: 2.6rem;
    }

    .field-tooltip {
        top: 21px;
    }

    .fieldset {
        >.field {
            >.label {
                font-weight: 400;
            }
        }
    }

    .form-login {
        margin-top: 28px;
        margin-bottom: 20px;

        .fieldset {
            .note {
                font-size: 14px;
                margin-top: 10px;
                display: block;
            }
        }
    }

    .form-shipping-address {
        margin-top: 28px;
        margin-bottom: 20px;

        .fieldset {
            .note {
                font-size: 14px;
                margin-top: 10px;
                display: block;
            }
        }
    }

    .shipping-address-items {
        font-size: 0;
    }

    .shipping-address-item {
        border: 2px solid transparent;
        line-height: 30px;
        margin: 0 0 20px;
        padding: 20px 35px 20px 20px;
        -webkit-transition: 0.3s border-color;
        transition: 0.3s border-color;
        width: 50%;
        display: inline-block;
        font-size: 14px;
        position: relative;
        vertical-align: top;
        word-wrap: break-word;
    }

    .shipping-address-item.selected-item {
        border-color: #4DCCCC;
        border-color: transparent;

        &:after {
            background: transparent;
            color: #fff;
            content: none;
            font-family: "icons-blank-theme";
            height: 27px;
            width: 29px;
            font-size: 27px;
            line-height: 21px;
            padding-top: 2px;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
        }

        .action-select-shipping-item {
            visibility: hidden;
        }
    }

    .action-show-popup {
        margin: 0 0 20px;

        >span {
            &:before {
                content: '+';
                padding-right: 5px;
            }
        }
    }

    .action-select-shipping-item {
        float: right;
        margin: 20px 0 0;
    }

    .edit-address-link {
        display: block;
        float: left;
        margin: 26px 5px 0 0;
    }
}

.modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
}

.checkout-index-index {
    .modal-title {
        margin: 0;
        line-height: 1.42857;
        font-size: 22px;
        margin: 0;
    }

    .modal-content {
        box-shadow: none;
        border: none;
    }

    .details-qty {
        span {
            color: #000;
            font-size: 14px;
            padding: 0;
        }
    }

    .minicart-items {
        .product-item-details {
            padding-left: 88px;
        }

        .product {
            >.product-item-photo {
                float: left;
            }

            >.product-image-container {
                float: left;
            }
        }

        .product-item {
            padding: 20px 0;
        }

        margin: 0;
        padding: 0;
        list-style: none none;
    }

    .opc-block-summary {
        .product-item {
            .product-item-inner {
                display: table;
                margin: 0 0 10px;
                width: 100%;
            }

            margin: 0;
            padding-left: 0;
        }
    }

    .modal-header {
        padding-top: 0;
    }

    .mark {
        background: transparent;
    }

    .totals.shipping {
        .label {
            font-size: 14px;
            padding: 0;
            color: #000;
        }
    }

    .nav-sections {
        display: none;
    }

    .nav-toggle {
        display: none;
    }

    .modal-custom {
        .action-close {
            &::before {
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 14px;
                color: #8f8f8f;
                content: '\f00d';
                font-family: 'FontAwesome';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            background: none;
            -moz-box-sizing: content-box;
            border: 0;
            box-shadow: none;
            line-height: inherit;
            margin: 0;
            padding: 0;
            text-shadow: none;
            font-weight: 400;
            display: inline-block;
            text-decoration: none;
            position: absolute;
            right: 40px;
            top: 30px;
            height: auto;
            overflow: visible;

            span {
                display: none;
            }
        }
    }

    .modal-popup {
        .action-close {
            &::before {
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 14px;
                color: #8f8f8f;
                content: '\f00d';
                font-family: 'FontAwesome';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            background: none;
            -moz-box-sizing: content-box;
            border: 0;
            box-shadow: none;
            line-height: inherit;
            margin: 0;
            padding: 0;
            text-shadow: none;
            font-weight: 400;
            display: inline-block;
            text-decoration: none;
            position: absolute;
            right: 40px;
            top: 30px;
            height: auto;
            overflow: visible;

            span {
                display: none;
            }
        }
    }

    .modal-slide {
        .action-close {
            &::before {
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 14px;
                color: #8f8f8f;
                content: '\f00d';
                font-family: 'FontAwesome';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            background: none;
            -moz-box-sizing: content-box;
            border: 0;
            box-shadow: none;
            line-height: inherit;
            margin: 0;
            padding: 0;
            text-shadow: none;
            font-weight: 400;
            display: inline-block;
            text-decoration: none;
            position: absolute;
            right: 40px;
            top: 30px;
            height: auto;
            overflow: visible;

            span {
                display: none;
            }
        }
    }

    .block-authentication {
        overflow: hidden;
    }

    .modal-inner-wrap {
        width: 100%;
    }
}

.opc-estimated-wrapper {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 18px 15px;

    .estimated-block {
        font-size: 18px;
        font-weight: 700;
        float: left;

        .estimated-label {
            display: block;
            margin: 0 0 5px;
        }
    }

    .minicart-wrapper {
        .action.showcart {
            &:before {
                color: #333;
            }
        }
    }
}

.opc-progress-bar {
    margin: 0 0 20px;
    counter-reset: i;
    font-size: 0;
}

.opc-progress-bar-item {
    margin: 0 0 10px;
    width: 50%;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;

    &:before {
        background: #e4e4e4;
        top: 19px;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &:first-child {
        &:before {
            border-radius: 6px 0 0 6px;
        }
    }

    &:last-child {
        &:before {
            border-radius: 0 6px 6px 0;
        }
    }

    >span {
        display: inline-block;
        padding-top: 45px;
        width: 100%;
        word-wrap: break-word;
        color: #e4e4e4;
        font-family: inherit;
        font-style: inherit;
        font-weight: 300;
        line-height: inherit;
        font-size: 18px;

        &:before {
            background: #e4e4e4;
            height: 38px;
            margin-left: -19px;
            width: 38px;
            border-radius: 50%;
            content: '';
            left: 50%;
            position: absolute;
            top: 0;
        }

        &:after {
            background: #e4e4e4;
            height: 38px;
            margin-left: -19px;
            width: 38px;
            border-radius: 50%;
            content: '';
            left: 50%;
            position: absolute;
            top: 0;
            background: #fff;
            height: 26px;
            margin-left: -13px;
            top: 6px;
            width: 26px;
            content: counter(i);
            counter-increment: i;
            color: #333;
            font-family: inherit;
            font-style: inherit;
            font-weight: 600;
            line-height: inherit;
            font-size: 18px;
        }
    }
}

.opc-progress-bar-item._active {
    &:before {
        background: #4DCCCC;
    }

    >span {
        color: #333;

        &:before {
            background: #4DCCCC;
        }

        &:after {
            content: "\f00c";
            font-family: "FontAwesome";
            font-size: 20px;
        }
    }
}

.field-tooltip {
    .label {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    cursor: pointer;
    position: absolute;
    right: 0;
    top: 1px;

    .field-tooltip-action {
        display: inline-block;
        text-decoration: none;

        >span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:before {
            -webkit-font-smoothing: antialiased;
            font-size: 24px;
            line-height: inherit;
            color: #bbb;
            content: "\f29c";
            font-family: "FontAwesome";
            margin: inherit;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
            vertical-align: middle;
            position: relative;
        }

        &:hover {
            &:before {
                color: #333;
            }
        }

        &:active {
            &:before {
                color: false;
            }
        }
    }

    .field-tooltip-content {
        background: #f4f4f4;
        border: 1px solid #999;
        border-radius: 1px;
        font-size: 14px;
        padding: 12px;
        width: 270px;
        display: none;
        left: 38px;
        position: absolute;
        text-transform: none;
        top: -9px;
        word-wrap: break-word;
        z-index: 2;

        &:before {
            border: 10px solid transparent;
            height: 0;
            width: 0;
            border-right-color: #f4f4f4;
            left: -21px;
            top: 12px;
            content: '';
            display: block;
            position: absolute;
            z-index: 3;
            border-right-color: #666666;
        }

        &:after {
            border: 10px solid transparent;
            height: 0;
            width: 0;
            border-right-color: #f4f4f4;
            left: -21px;
            top: 12px;
            content: '';
            display: block;
            position: absolute;
            z-index: 3;
            border-right-color: #f4f4f4;
            width: 1px;
            z-index: 4;
        }
    }
}

#checkoutSteps {
    list-style: none;
}

.opc-sidebar {
    .opc-block-summary {
        margin-bottom: 0;
    }
}

.authentication-dropdown {
    .modal-content {
        box-shadow: none;
        border: none;
        background: none;
        border-radius: 0;
    }

    box-sizing: border-box;

    .modal-inner-wrap {
        padding: 25px;
    }
}

.opc-block-shipping-information {
    .shipping-information-title {
        padding-bottom: 10px;
        color: #333;
        font-weight: 300;
        font-size: 2.6rem;
        border-bottom: 1px solid #ccc;
        margin: 0 0 20px;
        position: relative;

        .action-edit {
            top: 10px;
            display: inline-block;
            text-decoration: none;
            margin: 0;
            position: absolute;
            right: 0;
            border: none;
            background: no-repeat;

            >span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            &:before {
                -webkit-font-smoothing: antialiased;
                font-size: 18px;
                line-height: 20px;
                color: #8f8f8f;
                content: "\f040";
                font-family: "FontAwesome";
                margin: inherit;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                vertical-align: middle;
            }

            &:hover {
                &:before {
                    color: #333;
                }
            }

            &:active {
                &:before {
                    color: #8f8f8f;
                }
            }
        }
    }

    padding: 0 30px;
    margin-top: 20px;

    .shipping-information-content {
        line-height: 27px;

        .actions-toolbar {
            margin-left: 0;
        }
    }

    .ship-to {
        margin: 0 0 20px;
    }

    .ship-via {
        margin: 0 0 20px;
    }
}

.opc-block-summary {
    >.title {
        padding-bottom: 10px;
        color: #333;
        font-weight: 300;
        font-size: 2.6rem;
        display: block;
    }

    background: #f5f5f5;
    padding: 22px 30px;
    margin: 0 0 20px;

    .mark {
        .value {
            color: #999;
            display: block;
        }
    }

    .grand.incl+.grand.excl {
        .mark {
            border-top: 0;
            font-size: 14px;
            padding-top: 0;

            strong {
                font-weight: 400;
            }
        }

        .amount {
            border-top: 0;
            font-size: 14px;
            padding-top: 0;

            strong {
                font-weight: 400;
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    .items-in-cart {
        >.title {
            border-bottom: 1px solid #d1d1d1;
            padding: 10px 40px 10px 0;
            cursor: pointer;
            display: block;
            text-decoration: none;
            margin-bottom: 0;
            position: relative;

            >span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            &:after {
                -webkit-font-smoothing: antialiased;
                font-size: 15px;
                line-height: 12px;
                color: inherit;
                content: "\f078";
                font-family: "FontAwesome";
                margin: 3px 0 0;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                vertical-align: middle;
                position: absolute;
                right: 0;
                top: 10px;
            }

            &:hover {
                &:after {
                    color: inherit;
                }
            }

            &:active {
                &:after {
                    color: inherit;
                }
            }

            strong {
                font-size: 18px;
                font-weight: 300;
                margin: 0;
            }
        }

        .product {
            position: relative;
        }
    }

    .items-in-cart.active {
        >.title {
            &:after {
                content: "\f077";
            }
        }
    }

    .minicart-items-wrapper {
        margin: 0 -15px 0 0;
        max-height: 370px;
        padding: 15px 15px 0 0;
        border: 0;
    }

    .product-item {
        .product-item-inner {
            display: table;
            margin: 0 0 10px;
            width: 100%;
        }

        .product-item-name-block {
            display: table-cell;
            padding-right: 5px;
            text-align: left;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            font-size: 16px;
            font-weight: 400;
        }

        .price-including-tax+.price-excluding-tax {
            margin: 0;

            .price {
                font-size: 10px;
            }
        }
    }
}

.checkout-agreements-items {
    .checkout-agreements-item-title {
        padding-bottom: 10px;
        color: #333;
        font-weight: 300;
        font-size: 2.6rem;
        border-bottom: 0;
    }

    padding-bottom: 30px;

    .checkout-agreements-item {
        margin-bottom: 20px;
    }
}

.opc-progress-bar-item._complete {
    cursor: pointer;

    >span {
        color: #333;

        &:after {
            content: "\f00c";
            font-family: "FontAwesome";
            font-size: 20px;
        }
    }
}

.field.choice {
    .field-tooltip {
        display: inline-block;
        margin-left: 10px;
        position: relative;
        top: -3px;
    }

    .field-tooltip-action {
        line-height: 21px;
    }
}

.field._error {
    .control {
        input {
            border-color: #ed8380;
        }

        select {
            border-color: #ed8380;
        }

        textarea {
            border-color: #ed8380;
        }
    }
}

.field-tooltip._active {
    z-index: 100;

    .field-tooltip-content {
        display: block;
    }

    .field-tooltip-action {
        &:before {
            color: #333;
        }
    }
}

._keyfocus {
    .field-tooltip {
        .field-tooltip-action {
            &:focus {
                z-index: 100;

                &+.field-tooltip-content {
                    display: block;
                }

                &:before {
                    color: #333;
                }
            }
        }
    }
}

.payment-method {
    input[type="radio"] {
        display: inline-block;

        &+label {
            display: inline-block;
        }
    }
}

.checkout-shipping-method {
    .step-title {
        margin-bottom: 0;
    }

    .no-quotes-block {
        margin: 20px 0;
    }

    position: relative;
}

.methods-shipping {
    .actions-toolbar {
        .action.primary {
            margin: 20px 0 0;
        }
    }
}

.table-checkout-shipping-method {
    thead {
        th {
            display: none;
        }
    }

    tbody {
        td {
            border-top: 1px solid #ccc;
            padding-bottom: 20px;
            padding-top: 20px;

            &:first-child {
                padding-left: 0;
                padding-right: 0;
                width: 20px;
            }
        }

        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }
        }

        .row-error {
            td {
                border-top: none;
                padding-bottom: 10px;
                padding-top: 0;
            }
        }
    }
}

.shipping-policy-block.field-tooltip {
    top: 12px;

    .field-tooltip-action {
        color: #1979c3;
        cursor: pointer;

        &:before {
            display: none;
        }
    }

    .field-tooltip-content {
        width: 420px;
        top: 30px;
    }
}

.column.main {
    .opc-block-summary {
        .product-item {
            margin: 0;
            padding-left: 0;
        }
    }
}

.authentication-wrapper {
    float: right;
    margin-top: -60px;
    max-width: 50%;
    position: relative;
    z-index: 1;
}

._has-auth-shown {
    .authentication-wrapper {
        z-index: 900;
    }
}

.block-authentication {
    .block-title {
        font-size: 18px;
        border-bottom: 0;
        margin-bottom: 25px;

        strong {
            font-weight: 300;
        }
    }

    .field {
        .label {
            font-weight: 400;
        }
    }

    .actions-toolbar {
        margin-bottom: 5px;

        >.secondary {
            padding-top: 25px;
            text-align: left;
        }
    }

    .block[class] {
        margin: 0;

        ul {
            list-style: none;
            padding-left: 10px;
        }

        .field {
            .control {
                float: none;
                width: auto;
            }

            .label {
                float: none;
                width: auto;
            }
        }

        &+.block {
            border-top: 1px solid #c1c1c1;
            margin-top: 40px;
            padding-top: 40px;
            position: relative;

            &::before {
                height: 36px;
                line-height: 34px;
                margin: -17px 0 0 -18px;
                min-width: 36px;
                background: #fff;
                border: 1px solid #c1c1c1;
                border-radius: 50%;
                box-sizing: border-box;
                color: #c1c1c1;
                content: attr(data-label);
                display: inline-block;
                left: 50%;
                letter-spacing: normal;
                padding: 0 0.2rem;
                position: absolute;
                text-align: center;
                text-transform: uppercase;
                top: 0;
            }
        }
    }
}

.checkout-payment-method {
    .step-title {
        margin-bottom: 0;
    }

    .payment-method {
        &:first-child {
            .payment-method-title {
                border-top: 0;
            }
        }
    }

    .payment-method._active {
        .payment-method-content {
            display: block;
        }
    }

    .payment-method-title {
        border-top: 1px solid #ccc;
        padding: 10px 0;
        margin: 0;

        .payment-icon {
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
        }

        .action-help {
            display: inline-block;
            margin-left: 5px;
        }
    }

    .actions-toolbar {
        margin-top: 10px;
        text-align: left;
        display: block;
        overflow: hidden;
    }

    .payment-method-content {
        display: none;
        padding: 0 0 20px 22px;

        .fieldset {
            &:not(:last-child) {
                margin: 0 0 20px;
            }
        }
    }

    .field-select-billing {
        max-width: 500px;
    }

    .billing-address-form {
        max-width: 500px;
    }

    .billing-address-same-as-shipping-block {
        margin: 0 0 10px;
    }

    .payment-method-billing-address {
        margin: 0 0 20px;

        .primary {
            .action-update {
                margin-right: 0;
            }
        }

        .billing-address-details {
            line-height: 27px;
            padding: 0 0 0 23px;
        }
    }

    .payment-method-note+.payment-method-billing-address {
        margin-top: 20px;
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: 20px 0;
    }

    .payment-option._active {
        .payment-option-title {
            .action-toggle {
                &:after {
                    content: "\f078";
                    font-family: FontAwesome;
                }
            }
        }
    }

    .payment-option._collapsible {
        .payment-option-title {
            cursor: pointer;
        }

        .payment-option-content {
            display: none;
        }
    }

    .payment-option-title {
        border-top: 1px solid #ccc;
        padding: 20px 0;

        .action-toggle {
            color: #1979c3;
            display: inline-block;
            text-decoration: none;

            &:after {
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 16px;
                color: #8f8f8f;
                content: "\f078";
                font-family: FontAwesome !important;
                font-family: "icons-blank-theme";
                margin: 0 0 0 10px;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                vertical-align: middle;
            }

            &:hover {
                &:after {
                    color: #333;
                }
            }

            &:active {
                &:after {
                    color: #8f8f8f;
                }
            }
        }
    }

    .payment-option-content {
        padding: 0 0 20px 22px;
    }

    .payment-option-inner {
        margin: 0 0 20px;
    }

    .credit-card-types {
        padding: 0;

        .item {
            display: inline-block;
            list-style: none;
            margin: 0 5px 0 0;
            vertical-align: top;

            span {
                display: inline-block;
                padding-top: 6px;
                vertical-align: top;
            }
        }

        .item._active {
            font-weight: 700;

            img {
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
            }
        }

        .item._inactive {
            opacity: 0.4;
        }

        img {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            -webkit-transition: all 0.6s ease;
            transition: all 0.6s ease;
        }
    }

    .ccard {
        .fields {
            >.year {
                padding-left: 5px;
            }

            .select {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .month {
            .select {
                width: 140px;
            }
        }

        .year {
            .select {
                width: 80px;
            }
        }

        .number {
            .input-text {
                width: 225px;
            }
        }

        >.field.cvv {
            >.control {
                padding-right: 20px;
                width: auto;
            }
        }

        .cvv {
            .input-text {
                width: 55px;
            }
        }
    }

    .ccard.fieldset {
        >.field {
            .fields.group.group-2 {
                .field {
                    width: auto !important;
                }
            }
        }
    }
}

.checkout-agreements-block {
    margin-bottom: 20px;

    .action-show {
        vertical-align: baseline;
    }
}

.checkout-agreement-item-content {
    overflow: auto;
}

@media only screen and (max-width: 640px) {
    .opc-wrapper {
        .step-title {
            font-size: 18px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 0;
        }

        .form-login {
            border-bottom: 1px solid #ccc;
            margin: 0 0 15px;
            padding: 0 0 15px;
            margin-top: 28px;
        }

        .shipping-address-item {
            border-bottom: 1px solid #ccc;
            margin: 0 0 15px;
            padding: 0 0 15px;
            width: 100%;
        }

        .shipping-address-item.selected-item {
            padding: 15px 35px 15px 18px;
            border-bottom-width: 2px;

            .edit-address-link {
                right: 39px;
            }
        }

        .form-shipping-address {
            margin-top: 28px;
        }

        .action-select-shipping-item {
            float: none;
            margin-top: 10px;
            width: 100%;
        }

        .action-show-popup {
            width: 100%;
        }

        .edit-address-link {
            display: inline-block;
            text-decoration: none;
            margin: 0;
            position: absolute;
            right: 0;
            top: 1px;

            >span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            &:before {
                -webkit-font-smoothing: antialiased;
                font-size: 28px;
                line-height: 28px;
                color: #303030;
                content: "";
                font-family: "icons-blank-theme";
                margin: inherit;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                vertical-align: middle;
            }

            &:hover {
                &:before {
                    color: #303030;
                }
            }

            &:active {
                &:before {
                    color: #303030;
                }
            }
        }
    }

    .shipping-policy-block.field-tooltip {
        margin-bottom: 20px;
        position: relative;
        right: auto;
        top: auto;

        .field-tooltip-content {
            width: 300px;
            right: auto;

            &:before {
                right: auto;
            }

            &:after {
                right: auto;
            }
        }
    }
}

@media only screen and (min-width: 769px) {
    .opc-wrapper {
        width: 66.66667%;
        float: left;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        padding-right: 30px;

        .fieldset {
            >.field.required {
                position: relative;

                >label {
                    padding-right: 25px;

                    &:after {
                        margin-left: 10px;
                        position: absolute;
                        top: 9px;
                    }
                }
            }

            >.field._required {
                position: relative;

                >label {
                    padding-right: 25px;

                    &:after {
                        margin-left: 10px;
                        position: absolute;
                        top: 9px;
                    }
                }
            }
        }

        .form-login {
            max-width: 500px;
        }

        .form-shipping-address {
            max-width: 500px;
        }
    }

    .opc-estimated-wrapper {
        display: none;
    }

    .opc-progress-bar-item {
        width: 185px;
    }

    .checkout-index-index {
        .modal-popup {
            .form-shipping-address {
                max-width: 500px;
            }

            .modal-footer {
                .action-save-address {
                    float: right;
                    margin: 0 0 0 10px;
                }
            }
        }
    }

    .checkout-shipping-method {
        .actions-toolbar {
            >.primary {
                float: right;
            }

            .action.primary {
                margin: 0;
            }
        }
    }

    .table-checkout-shipping-method {
        width: auto;
    }

    .opc-sidebar {
        margin: 46px 0 20px;
        width: 33.33333333%;
        float: right;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }

    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }

    .authentication-dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: #fff;
        border: 1px solid #aeaeae;
        text-align: left;
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transition: visibility 0 linear 0.15s, -webkit-transform linear 0.15s;
        transition: visibility 0 linear 0.15s, -webkit-transform linear 0.15s;
        transition: transform linear 0.15s, visibility 0 linear 0.15s;
        transition: transform linear 0.15s, visibility 0 linear 0.15s, -webkit-transform linear 0.15s;
        visibility: hidden;

        .fieldset {
            overflow: hidden;
        }
    }

    .authentication-dropdown._show {
        z-index: 100;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition: visibility 0 linear 0, -webkit-transform linear 0.15s;
        transition: visibility 0 linear 0, -webkit-transform linear 0.15s;
        transition: transform linear 0.15s, visibility 0 linear 0;
        transition: transform linear 0.15s, visibility 0 linear 0, -webkit-transform linear 0.15s;
        visibility: visible;
    }

    .authentication-wrapper {
        width: 66.66667%;
        text-align: right;
    }

    .block-authentication {
        .actions-toolbar {
            margin-bottom: 5px;
            margin-left: 15px;
            margin-right: 15px;

            >.primary {
                float: right;
                margin-right: 15;

                .action {
                    margin-right: 0;
                }
            }

            >.secondary {
                float: left;
                margin-right: 2rem;
                padding-top: 1rem;
            }
        }

        .block-title {
            font-size: 26px;
            border-bottom: 0;
            margin-bottom: 25px;
        }
    }

    .popup-authentication {
        .modal-inner-wrap {
            min-width: 769;
            width: 60%;
        }

        .block-authentication {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            border-top: 1px solid #c1c1c1;
        }

        .block[class] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            box-sizing: border-box;
            float: left;
            padding: 10px 30px 0 0;
            width: 50%;

            &+.block {
                border-left: 1px solid #c1c1c1;
                border-top: 0;
                margin: 0;
                padding: 10px 0 0 40px;

                &::before {
                    left: 0;
                    top: 50%;
                }
            }
        }
    }

    .form-login {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
    
    .block-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .actions-toolbar {
        margin-bottom: 0;
        margin-top: auto;
    }
}

.checkout-payment-method {
    .payment-methods {
        .actions-toolbar {
            .primary {
                float: right;
                margin: 0;
            }
        }
    }

    .fieldset {
        >.field-select-billing {
            >.control {
                float: none;
                width: 100%;
            }
        }
    }

    .payment-method-content {
        .fieldset {
            >.field {
                margin: 0 0 20px;
            }

            >.field.type {
                .control {
                    margin-left: 25.8%;
                }
            }

            >.field.type.no-detection {
                .control {
                    margin-left: 0;
                }
            }
        }
    }

    .payment-option-title {
        padding-left: 22px;
    }

    .payment-option-content {
        .payment-option-inner+.actions-toolbar {
            margin-left: 0;
        }
    }
}

.payment-method-billing-address {
    .action-update {
        float: right;
    }

    .actions-toolbar {
        .action-cancel {
            margin: 0 20px 0 0;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .checkout-index-index {
        .modal-popup {
            .modal-inner-wrap {
                margin-left: -400px;
                width: 800px;
                left: 50%;
            }
        }
    }

    .opc-wrapper {
        .shipping-address-item {
            width: 33.3333%;

            &:before {
                background: #ccc;
                height: calc(100% - 20px);
                content: '';
                left: 0;
                position: absolute;
                top: 0;
                width: 1px;
            }

            &:nth-child(3n + 1) {
                &:before {
                    display: none;
                }
            }
        }

        .shipping-address-item.selected-item {
            &:before {
                display: none;
            }

            &+.shipping-address-item {
                &:before {
                    display: none;
                }
            }
        }
    }

    .table-checkout-shipping-method {
        min-width: 500px;
    }
}

@media only screen and (max-width: 480px) {
    .modal-popup {
        .field-tooltip {
            .field-tooltip-content {
                width: 200px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .checkout-payment-method {
        .payment-methods {
            margin: 0 -15px;
        }

        .payment-method-title {
            padding: 15px;
        }

        .payment-method-content {
            padding: 0 15px 20px;
        }

        .payment-method-billing-address {
            .action-cancel {
                margin-top: 10px;
            }
        }

        .payment-option {
            margin: 0 -15px;

            .payment-option-title {
                padding: 15px;
            }

            .payment-option-content {
                padding: 0 15px 20px;
            }
        }
    }
}
