body {
    overflow-x: hidden;
    color: #000;
    font-family: 'Oswald', sans-serif;

    #maincontent {
        padding: 20px 30px;
    }

    &>.loading-mask {
        z-index: 9999;
    }
}

button.primary {
    padding: 11px 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    font-weight: 700;
    text-transform: uppercase;
    background: #005775;
    color: #fff;

    &:hover {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        border: none;
    }

    &:focus {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        border: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
}

.button-primary {
    padding: 11px 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;

    &:hover {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        border: none;
    }

    &:focus {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        border: none;
    }
}

button.primary[disabled] {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;
}

.button-primary[disabled] {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;
}

button.secondary {
    font-family: 'Oswald', sans-serif;
    padding: 11px 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;
}

.button-secondary {
    font-family: 'Oswald', sans-serif;
    padding: 11px 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;
}

.clearfix {
    display: block;
    content: "";
    clear: both;
}

.label {
    color: #999;
    font-size: 13px;
    padding: 0;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: normal;
}

.form-check-label {
    color: #999;
    font-size: 13px;
    padding: 0;
    text-align: left;
    font-weight: normal;
}

img {
    max-width: 100%;
}

.account {
    .page-title-wrapper {
        h1.page-title {
            display: inline;
            font-size: 34px;
            margin-bottom: 30px;
        }
    }
}

.catalogsearch-result-index {
    .page-title-wrapper {
        h1.page-title {
            font-size: 34px;
            margin-bottom: 30px;
        }
    }
}

.catalog-product-view {
    .page-title-wrapper {
        h1.page-title {
            font-size: 34px;
            margin-bottom: 30px;
        }
    }
}

.page-header {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;

    .showas-icons {
        .fa {
            font-size: 40px;
            vertical-align: middle;
            padding: 0px 10px;
        }

        .showcart {
            position: relative;
            width: auto;

            .text {
                width: auto;
                display: table;
            }
        }

        .counter {
            width: auto;
            display: table;
            height: auto;

            .counter-number {
                position: absolute;
                display: block;
                width: 20px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                top: 40%;
                background-color: #fff;
                height: 20px;
                line-height: 20px;
                border-radius: 100%;
                font-size: 11px;
                font-weight: bold;
            }
        }

        #minicart-content-wrapper {
            margin-right: 15px;
            margin-left: 15px;
        }
    }

    .block-minicart {
        .subtitle.empty {
            line-height: normal;
        }
    }
}

.top-links-wrapper {
    min-height: 120px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    line-height: 50px;

    .minicart-wrapper {
        .showcart {
            &:hover {
                .fa {
                    color: #ff7300 !important;
                }
            }
        }
    }

    .fa-user-circle {
        &:hover {
            color: #ff7300 !important;
        }
    }

    .additional-header-links {
        .wishlist-icon {
            a {
                &:hover {
                    color: #ff7300 !important;
                }
            }
        }

        a {
            font-size: 15px;
            line-height: 60px;

            &:hover {
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }
        }

        .fa {
            font-size: 40px;
            padding: 0px 12px;
        }
    }

    .material-icons {
        font-size: 28px;
        vertical-align: middle;
    }
}

.top-logo-wrapper {
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        vertical-align: middle;
        display: block;
    }
}

.top-search-wrapper {
    min-height: 120px;
}

.top-award-logo {
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-wrapper {
    .breadcrumb {
        margin-bottom: 20px;
        list-style: none;
        background-color: #fff;
        margin-top: 10px;
        font-family: 'Oswald', sans-serif;
        display: block;
    }
}

#btn-minicart-close {
    display: none;
}

.showcart {
    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }

    text-decoration: none;
}

.message {

    &.info,
    &.error,
    &.notice {
        margin: 0 0 10px;
        padding: 12px 20px 12px 25px;
        display: block;
        font-size: 1.3rem;
        background: #fdf0d5;
        color: #6f4400;
        padding-left: 45px;
        position: relative;

        a {
            color: #1979c3;

            &:hover {
                color: #006bb4;
            }

            &:active {
                color: #006bb4;
            }
        }

        &>* {
            &:first-child {
                &:before {
                    content: '\f071';
                    -webkit-font-smoothing: antialiased;
                    font-size: 24px;
                    line-height: 24px;
                    color: #c07600;
                    font-family: 'FontAwesome';
                    margin: -12px 0 0;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    left: 0;
                    top: 22px;
                    width: 45px;
                    position: absolute;
                    text-align: center;
                }
            }
        }
    }

    &.error {
        background: #fae5e5;
        color: #e02b27;

        &>* {
            &:first-child {
                &:before {
                    color: #b30000;
                }
            }
        }
    }

    &.notice {
        &>* {
            &:first-child {
                &:before {
                    content: '\f0f3';
                }
            }
        }
    }

    &.success {
        background: #e5efe5;
        color: #006400;

        &>* {
            &:first-child {
                &:before {
                    content: '\f058';
                    color: #006400;
                }
            }
        }
    }
}

.no-grid-padding {
    padding: 0 !important;
}

hr {
    margin: 0;
    padding: 0;
    border: none;
}

hr.content-divider {
    background: #cfe1ec;
    height: 3px;
    width: 100%;
    clear: both;
    float: left;
    display: block;
    margin: 40px 0 40px 0;
}

.additional-header-links {
    ul {
        list-style: none;
        padding: 0;
        border: navajowhite;
        margin: 0;
        display: flex;
        align-items: center;
        height: 100%;

        li {
            position: relative;

            .counter {
                position: absolute;
                display: block;
                width: 20px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                top: 47%;
                background-color: #fff f;
                height: 20px;
                line-height: 20px;
                border-radius: 100%;
                font-size: 11px;
                font-weight: bold;
            }

            a {
                display: flex;
                align-items: center;
            }
        }

        li.wishlist-icon {
            .counter {
                background-color: #005775;
                top: 50%;
                height: 20px;
                left: 50%;
                padding: 0;
                margin: auto;
                line-height: 20px;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                width: 20px;
                color: #fff;
                right: inherit;
            }

            a {
                position: relative;

                &:hover {
                    color: #ff7300;

                    .counter {
                        background-color: #ff7300;
                    }
                }
            }
        }

        li.compare {
            .counter {
                background-color: #005775;
                top: 50%;
                height: 20px;
                left: 50%;
                padding: 0;
                margin: auto;
                line-height: 20px;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                width: 20px;
                color: #fff;
                right: inherit;
                top: 5px;
                right: 5px;
                color: #fff;
                background-color: #005775;
                left: unset;
                transform: translate(0);
            }

            a {
                position: relative;

                &:hover {
                    color: #ff7300;

                    .counter {
                        background-color: #ff7300;
                    }
                }
            }

            .material-icons {
                padding: 0px 10px;
            }

            .no-display {
                display: none;
            }
        }
    }
}

.additonal-showas-icons {
    .material-icons {
        font-size: 45px;
        vertical-align: top;
        line-height: 63px;
    }
}

.showas-icons-text {
    i.fa {
        font-size: 23px;
        vertical-align: middle;
        margin-right: 5px;
    }
}

.load.indicator {
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    position: absolute;

    &:before {
        background: transparent url('../images/loader-2.gif') no-repeat 50% 50%;
        border-radius: 5px;
        height: 160px;
        width: 160px;
        bottom: 0;
        box-sizing: border-box;
        content: '';
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
    }

    >span {
        display: none;
    }
}

.loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);

    .loader {
        >img {
            bottom: 0;
            left: 0;
            margin: auto;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 100;
        }

        >p {
            display: none;
        }
    }
}

._block-content-loading {
    position: relative;
}

.top-headerbar {
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .toplinks-left {
        li {
            &:first-child {
                padding-left: 0;
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: inline-block;
            list-style: none;
            padding: 0 15px;
        }
    }
}

.calltoaction-container {
    text-transform: uppercase;
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    background: #fff;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    border-bottom: 1px solid #eaeaea;
    color: #3f7096;

    .call-action-block+.call-action-block {
        border-left: 1px solid #eaeaea;
    }
}

.page-footer-before {
    margin-top: 30px;
    padding-bottom: 30px;
    background: #007096;

    .footer-col-title {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: #fff;
    }

    .footer-links {
        margin-top: 50px;
        margin-bottom: 10px;
        display: block;
        overflow: hidden;

        ul {
            padding-left: 0;
            list-style: none;

            li {
                a {
                    font-family: 'Oswald', sans-serif;
                    font-size: 14px;
                    color: #fff;
                }
            }
        }
    }
}

.navbar {
    border: 0 !important;
}

.io-layered-navigation-left {
    #filter-active-filters {
        ol {
            list-style: none;
            padding: 0;

            .remove {
                float: right;
            }

            .filter-value {
                font-size: 13px;
            }

            .filter-label {
                display: block;
                width: 100%;
                font-weight: 600;
                font-family: 'Oswald';
                font-size: 14px;
            }
        }
    }

    .panel {
        background-color: #fff !important;
        border-radius: 0 !important;
        border: 0;

        .panel-heading {
            font-family: 'Oswald', sans-serif;
            border: 0;

            a {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                text-decoration: none !important;
            }
        }

        .panel-title {
            >a {
                &:before {
                    float: right !important;
                    font-family: FontAwesome, sans-serif;
                    content: "\f077";
                    padding-right: 5px;
                }
            }

            >a.collapsed {
                &:before {
                    float: right !important;
                    content: "\f078";
                }
            }
        }
    }
}

.footer-block.newsletter {
    margin-top: 50px;
    margin-bottom: 40px;

    .title-icon-container {
        >div {
            display: inline-block;
            vertical-align: top;
        }
    }

    .icon {
        font-size: 50px;
    }

    .title {
        color: #fff;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 0;
        font-family: 'Oswald', sans-serif;

        i {
            margin-right: 20px;
        }
    }

    .subtitle {
        font-size: 17px;
        letter-spacing: 0.2px;
        font-weight: 400;
        margin-bottom: 10px;
        display: block;
    }

    .form-group {
        width: 70%;

        .input-group {
            width: 100%;

            input {
                width: 100%;
                font-size: 13px;
                padding-top: 10px;
                height: auto;
                padding-bottom: 10px;
                padding-left: 15px;
            }
        }
    }
}

.page-footer {
    padding-top: 60px;
    padding-bottom: 60px;
    background: #006587;

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #005775;
    }

    .footer-methods {
        h4 {
            display: inline-block;
            vertical-align: middle;
            font-size: 19px;
            font-weight: 600;
            margin-right: 10px;
            color: #fff;
        }

        .payment-link {
            padding: 5px;
            min-width: 58px;
            min-height: 50px;
            font-size: 32px;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
            border-radius: 100%;
            margin-left: 15px;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .footer-social {
        padding-top: 10px;
        padding-bottom: 20px;

        h4 {
            display: inline-block;
            vertical-align: middle;
            font-size: 21px;
            font-weight: 600;
            margin-right: 10px;
            text-transform: uppercase;
            font-family: 'Oswald', sans-serif;
            color: #fff;
        }

        a.social-link {
            padding: 5px;
            min-width: 40px;
            min-height: 20px;
            font-size: 23px;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
            border-radius: 100%;
            margin-left: 15px;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .copy-social {
        hr {
            border-color: #c9c9c9 !important;
        }
    }

    .copyright {
        padding-top: 20px;
        display: block;
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 400;
        color: #fff;

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            &:active {
                text-decoration: none;
            }
        }
    }

    .company_info {
        font-size: 11px;
        text-transform: none;
    }
}

.navbar-mainnavigation {
    background: #fff none repeat scroll 0 0;
    margin-top: 51px;
}

.toolbar-products {
    display: none;
}

.menu-static-top {
    margin-bottom: 10px;
}

.menu-static-bottom {
    margin-top: 10px;
}

.password-strength-meter {
    background-color: #f4f4f4;
    height: 32px;
    line-height: 32px;
    padding: 0 9px;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
}

.password-strength-meter {
    &-0 {
        .password-strength-meter {
            &:before {
                background-color: #f4f4f4;
                width: 100%;
            }
        }
    }

    &-1 {
        .password-strength-meter {
            &:before {
                background-color: #ffafae;
                width: 25%;
            }
        }
    }

    &-2 {
        .password-strength-meter {
            &:before {
                background-color: #ffd6b3;
                width: 50%;
            }
        }
    }

    &-3 {
        .password-strength-meter {
            &:before {
                background-color: #c5eeac;
                width: 75%;
            }
        }
    }

    &-4 {
        .password-strength-meter {
            &:before {
                background-color: #81b562;
                width: 100%;
            }
        }
    }
}

.captcha-reload {
    background: transparent;
    border: transparent;

    span {
        display: none;
    }
}

#captcha-container-user_forgotpassword {
    margin-bottom: 30px;
    margin-top: 20px;
}

.required {
    >.label {
        >span {
            &::after {
                content: "*";
                vertical-align: top;
                color: red;
                margin-left: 3px;
            }
        }
    }
}

input.mage-error {
    border-color: #ed8380;
}

select.mage-error {
    border-color: #ed8380;
}

textarea.mage-error {
    border-color: #ed8380;
}

div.mage-error[generated] {
    color: #e02b27;
    font-size: 1.2rem;
}

.page.messages {
    display: block;
    clear: both;
    max-width: 1170px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    .alert {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;

        &-success {
            color: #3c763d;
            background-color: #dff0d8;
            border-color: #d6e9c6;
        }

        &-info {
            color: #31708f;
            background-color: #d9edf7;
            border-color: #bce8f1;
        }

        &-warning {
            color: #8a6d3b;
            background-color: #fcf8e3;
            border-color: #faebcc;
        }

        &-error {
            color: #a94442;
            background-color: #f2dede;
            border-color: #ebccd1;
        }
    }
}

.no-gutter-right {
    padding-right: 0 !important;
}

.no-gutter-left {
    padding-left: 0 !important;
}

.banner-wrap {
    margin-bottom: 30px;

    img {
        width: 100%;
        height: auto;
    }
}

/* ** Turn off all blurs ** */
* {
    &:focus {
        outline: none !important;
    }
}

input {
    &:focus {
        outline: none !important;
    }
}

select {
    &:focus {
        outline: none !important;
    }
}

textarea {
    &:focus {
        outline: none !important;
    }
}

button {
    &:focus {
        outline: none !important;
    }
}

.navbar-brand {
    padding: 0 !important;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul.footer-awards {
    padding: 20px 0;
    display: inline-block;

    li {
        float: left;
        display: inline;
        margin: 0 5px;
    }
}

a.action {
    display: inline-flex;
    align-items: center;
}

button.secondary[disabled],
button.secondary:hover,
button.secondary:focus,
.button-secondary[disabled],
.button-secondary:hover,
.button-secondary:focus {
    font-family: 'Oswald', sans-serif;
    background-color: $brand-secondary !important;
    border-color: $brand-secondary !important;
    color: $brand-secondary !important;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;
}

.control select,
.input-text {
    @extend .form-control;
}

#search_mini_form {
    position: relative;
    vertical-align: middle;
    margin-top: 40px;
    margin-bottom: 30px;

    input[type="text"] {
        padding: 10px 15px;
        height: auto;
        width: 100%;
        border-radius: 0;
        border: 1px solid $brand-light-teal;
    }

    .btn {
        position: absolute;
        right: 0;
        top: 0;
        line-height: 8px;
        vertical-align: middle;
        padding: 10px;
        width: 100%;
        max-width: 45px;
        max-height: 45px;
        height: 100%;
        border-color: $brand-orange !important;
        background-color: $brand-orange !important;
        text-align: center;

        i {
            font-size: 20px;
        }
    }
}

/* ** HEADINGS ** */

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $brand-primary;
    font-family: 'Oswald', san-serif;
}

p {
    color: $gray-base;
}

.captcha-reload::before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    content: "\f021";
    font-size: 26px;
    vertical-align: bottom;
    display: inline-block;

    &:hover {
        @extend .fa-spin;
    }
}

@media all and (min-width: 993px) {
    .page-wrapper {
        .breadcrumb {
            padding: 8px 30px;
        }
    }
}

@media all and (min-width: 769px) {
    .navbar-collapse.collapse {
        display: table;
        margin: 0 auto !important;
        text-align: center;
    }

    li.level2 {
        padding: 0;
    }

    .level1 {
        >ul {
            >li {
                >a {
                    padding: 5px 0;
                    font-weight: normal !important;
                    text-transform: none !important;
                }
            }
        }
    }

    .level2 {
        >a {
            padding: 0 !important;
        }
    }

    .menuheading {
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #333;
    }

    .megamenu-viewall {
        text-align: center;
    }

    .navbar-megamenu {
        margin-bottom: 0;
    }
}

@media all and (max-width: 1200px) {
    .page-header .showas-icons .counter .counter-number {
        top: 45%;
    }
}

@media all and (max-width: 992px) {
    body {
        #maincontent {
            padding: 20px 15px;
        }
    }
}

@media all and (max-width: 768px) {
    .page-wrapper {
        .breadcrumb {
            display: none;
        }
    }
}

@media all and (max-width: 480px) {
    .page-footer-before {
        .footer-links {
            .col-xs-6 {
                &:nth-child(3n) {
                    clear: left;
                }
            }
        }
    }
}
