.viewbrands-index-index {
    h1 {
        text-transform: uppercase;
        color: #357097;
        font-size: 24px;
        font-weight: 800;
    }
}

.brands-list-wrapper {
    border-top: 3px solid transparent;
    position: relative;
    margin-top: 40px;

    &:before {
        content: '';
        position: absolute;
        height: 3px;
        top: 0px;
        left: 5px;
        right: 5px;
        background-color: #cfe1ec;
    }
    
    .brands-list {
        margin: 0 -10px;
        padding: 40px 0;

        .brand-item {
            position: relative;
            padding-left: 10px;
            padding-right: 10px;

            .featured-image {
                img {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .brand-title {
                background: #357097;
                padding: 10px;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                text-align: center;

                a {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }

                    &:active {
                        text-decoration: none;
                    }
                }

            }

        }

    }
}

.brand-repeater-blocks,
.brand-additional-content {

    .col-md-12,
    .col-sm-12,
    .col-xs-12,
    .col-lg-12 {
        img {
            max-width: 100%;
            width: 100%;
            height: auto;
        }
    }

    .col-md-4,
    .col-md-8 {
        img {
            max-width: 100%;
            width: 100%;
            height: auto;
        }
    }
}
