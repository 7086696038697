.catalogsearch-result-index,
.catalog-category-view {
    .page-title-wrapper {
        text-align: left;

        .page-title {
            font-family: 'Oswald', sans-serif;
            color: #000;
            font-size: 40px;
            font-weight: 400;
            text-transform: uppercase;
            padding: 0px;
            margin-bottom: 20px;
        }
    }

    .category-page-children {
        padding: 0;
    }

    .category-description {
        font-family: 'Oswald', sans-serif;
        font-size: 14px;
        font-weight: 100;
        line-height: normal;
        margin-bottom: 30px;
        padding: 0px;
        padding-bottom: 30px;
        border-bottom: 2px solid #cfe1ec;

        .morecontent {
            display: none;
        }
    }

    .rating-summary {
        display: none !important;
    }

    .category-item {
        .category-item-inner {
            position: relative;
            background: #f4f4f4;
            margin-bottom: 30px;
            border: 1px solid #eaeaea;
            overflow: hidden;

            .category-item-image {
                // background: url('../images/subcat-repeater.jpg') repeat-x 0 0;
                padding-top: 30px;
                min-height: 250px;
            }

            .category-item-name {
                min-height: 95px;

                a {
                    font-family: 'Oswald', sans-serif;
                    text-transform: uppercase;
                    font-size: 28px;
                    font-weight: 400;
                    color: #000;
                    margin-bottom: 15px;
                    display: block;
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }

            .category-item-action {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;

                .btn-primary {
                    font-size: 13px;
                    width: 100%;
                }
            }

            .category-item-description {
                font-family: 'Oswald', sans-serif;
                font-size: 11px;
                text-align: center;
                padding: 0 30px;
                line-height: 13px;
                color: #333;
                min-height: 100px;
            }
        }
    }

    .category-children {
        margin-bottom: 30px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;

                a {
                    font-size: 13px;
                    text-decoration: underline;

                    &:hover {
                        font-weight: bold;
                        font-size: 13px;
                    }

                    &:focus {
                        font-weight: 600;
                        font-size: 13px;
                    }
                }
            }

            li+li {
                margin-left: 10px;
                padding: 10px;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .products-grid {
        .row {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-wrap: wrap;
        }

        .row>[class*='col-'] {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
        }

        .product-item-photo {
            position: relative;

            .button-quickview {
                z-index: 2;
            }

            .grid-wish-compare {
                position: absolute;
                right: 5px;
                bottom: 0;
                z-index: 2;

                a {
                    text-decoration: none;

                    &:before {
                        font-family: "FontAwesome";
                        content: "\f004";
                        font-size: 18px;
                        color: #ed7600;
                    }

                    &:hover {
                        &:before {
                            color: #000;
                        }
                    }
                }
            }
        }     

        .price-box {
            display: block;
            font-weight: 600;
            margin-top: 5px;
        }

        .swatch-option {
            width: 20px;
            min-width: auto;
            margin: 0 5px 5px 0;
        }
    }
}

.product-image {
    &-container {
        display: inline-block;
        max-width: 100%;
        vertical-align:top;
    }
    &-wrapper {
        height: 0;
        display: block;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }
    &-photo {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: auto;
        max-width: 100%;
    }
}   

.io-layered-navigation-left {
    ol {
        list-style: circle;
    }
}

@media all and (max-width: 992px) {
    .catalogsearch-result-index,
    .catalog-category-view {
        .products-grid {
            .row>[class*='col-'] {
                margin-bottom: 25px;
            }
        }
    }
}
