.about-blocks-block-container{
	margin-top: 40px;
	margin-bottom: 40px;
}
.top-award-logo img{
float:right;
}
.ioacf-block {
    position: relative;
	.ioacf-block-details {
		position: absolute;
		top: 13%;
		padding: 10px 25px;
		background: #fff;
	}
	.ioacf-block-title {
		a{
			font-size: 36px;
			line-height: 36px;
			font-weight: 700;
			color: #333333;
		}
	}
	.ioacf-block-button {
		a{
			font-size: 16px;
			line-height: 16px;
			font-weight: 700;
			font-family: Oswald;
			text-transform: uppercase;
			color: #333333;
		}
	}
}
.pos-align-vbottom{
	.ioacf-block-details {
		top: 65%;
	}
}
.pos-align-vtop{
	.ioacf-block-details {
		top: 13%;
	}
}
.pos-align-vmiddle {
	.ioacf-block-details {	
		top: 40%;
	}
}

.slideshow-main {

    margin-bottom: 50px;

}
.ioacf-blocks {
	.ioacf-product-block-image {
		height: 260px;
		img {
			max-height: 100%;
			height: 100%;
			margin: auto;
			vertical-align: middle;
			display: block;
			width: auto;
		}
	}
}
.homepage-content-block-container {
	color: #333333;
	h1 {
		font-family: 'Oswald', san-serif;
		text-transform: uppercase;
		font-size: 30px;
		margin-bottom: 30px;
		color: #333333;
	}
}
.cms-index-index {
	.column.main {

		overflow: hidden;
		clear: both;

	}
	.blockcategory_blocks-block .ioacf-block-details {
		display: none;
	}
	.carousel-primary-1 {
		.slick-next {
			background: url('../images/new-slick-arrows.gif') no-repeat -33px 0;
			height:63px;
			width: 33px;
			right: 35px;
			z-index: 99;
			&::before {
				content: 0;
			}
		}
		.slick-prev {
			background: url('../images/new-slick-arrows.gif') no-repeat 0 0;
			height:63px;
			width: 33px;
			left: 35px;
			z-index: 99;
			&::before {
				content: 0;
			}
		}
	}
}