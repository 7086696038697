@media (max-width: 1200px) {
    div.iomegamenu .navbar-megamenu .navbar-nav>li>a {
        padding: 6px 6px;
    }

    .top-links-wrapper .additional-header-links .fa,
    .page-header .showas-icons .fa {
        padding: 0px 5px;
    }

    .minicart-wrapper {
        padding-bottom: 5px;
    }
}

@media (max-width: 992px) {
    #mobile-bootstrap-offcanvas {
        background: #007096;
        padding: 0;
        overflow: auto;

        ul {
            margin: 0;
            padding: 0;

            li {
                background: #007096;
                display: block;
                width: 100%;
                border: none;
                background: #007096 !important;

                &:last-of-type {
                    border-bottom: 0px;
                }

                ul.shown {
                    display: unset;
                }

                a {
                    background: #007096 !important;
                    color: #fff !important;
                }
            }
        }

        .dropdown-menu {
            >li {
                >a {
                    color: #fff !important;
                }
            }
        }

        >ul {
            margin: 50px -15px 0px;

            >li {
                >a {
                    padding: 14px 20px;
                }

                >ul {
                    >li {
                        >a {
                            padding: 5px 20px !important;
                        }

                        >ul {
                            >li {
                                >a {
                                    padding: 5px 30px;
                                }
                            }
                        }
                    }
                }
            }
        }

        li.dropdown {
            &:hover {
                >a {
                    border-bottom: none;

                    #mobile-bootstrap-offcanvas {
                        li.dropdown {
                            &:hover {
                                background: #525050 !important;
                                color: #fff !important;
                            }
                        }
                    }
                }
            }
        }

        li {
            &:hover {
                &:not(.open) {
                    &:not(.shown) {
                        &:not(.level1) {
                            &:not(.level2) {
                                &:not(.level3) {
                                    &:not(.level4) {
                                        ul {
                                            display: none !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .view-all-container {
            &:hover {
                padding: 0 15px;
            }

            a {
                padding: 5px 15px;
                margin: 0;
                text-align: left;
                border: navajowhite;
            }
        }

        .navbar-nav {
            .open {
                .dropdown-menu {
                    .dropdown-header {
                        padding: 4px 20px;
                    }
                }
            }

            >li {
                &:hover {
                    >a {
                        margin: 0 !important;
                        border-top: 1px solid #000 !important;
                    }
                }

                >a {
                    margin: 0 !important;
                    border-top: 1px solid #000 !important;
                }

                &:first-child {
                    &:hover {
                        >a {
                            border-top: none !important;
                        }
                    }

                    >a {
                        border-top: none !important;
                    }
                }
            }
        }

        &.navbar-nav {
            .open {
                .dropdown-menu {
                    >li {
                        >a {
                            padding: 4px 20px;
                        }
                    }
                }
            }
        }
    }

    .mobile-close-menu {
        padding: 10px 21px;
        height: 60px;
        color: #fff;
        z-index: 99999;
        cursor: pointer;
        font-weight: 900;
        position: absolute;
        left: 0;
        width: 130px;
        display: flex;
        align-items: center;
        z-index: 99999;
    }

    .mobile-menu {
        margin: 0;
        padding: 0;

        .navbar {
            min-height: 0;
        }
    }

    .top-award-logo {
        img {
            max-height: 45px;
        }
    }

    .mobilesearchbox {
        background: #525050;
        padding: 15px 0px;

        .form-control {
            display: inline-block;
            width: 84%;
            padding: 18px;
            border-radius: 0;
            vertical-align: middle;
        }

        .btn-primary {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .mobile-search-form {
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
            margin-left: 10px;
        }
    }

    .page-header {
        .showas-icons {
            .counter {
                .counter-number {
                    width: 16px;
                    height: 16px;
                    line-height: 16px;
                    font-size: 10px;
                    top: 42%;
                }
            }
        }
    }

    // body {
    //     #maincontent {
    //         .columns {
    //             margin-left: -20px;
    //             margin-right: -20px;
    //         }
    //     }
    // }

    body #maincontent h1 {
        font-size: 26px;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {

    .top-links-wrapper,
    .top-logo-wrapper,
    .top-search-wrapper,
    .top-award-logo {
        min-height: 50px;
    }

    .page-header {
        &>.header {
            border-bottom: 2px solid #525050;
        }
    }

    .additional-header-links ul {
        li {
            vertical-align: top;

            &.wishlist-icon,
            &.compare {
                .counter {
                    width: 15px;
                    height: 15px;
                    line-height: 14px;
                }
            }

            .counter {
                font-size: 9px;
                line-height: 14px;
            }

            &.compare {
                padding: 0px 5px;

                .material-icons {
                    padding: 0px;
                }

                .counter {
                    top: -3px;
                    right: -5px;
                }
            }
        }
    }

    .top-links-wrapper {
        padding: 7px 10px;

        .additonal-showas-icons {
            .material-icons {
                font-size: 33px;
                vertical-align: top;
                padding: 10px 10px;
            }
        }

        .additional-header-links {
            a {
                margin-right: 5px;
                font-size: 15px;
                line-height: 34px;
                display: block;
                text-align: center;
                height: 30px;
                width: 30px;

                .fa {
                    font-size: 30px;
                    padding: 0;
                    width: 30px;
                    height: 30px;
                }
            }

            .wishlist-icon {
                line-height: normal;
                vertical-align: top;
                padding: 0;

                .fa {
                    font-size: 30px;
                }

                a {
                    .material-icons {
                        font-size: 33px;
                        vertical-align: top;
                        padding: 0;
                        line-height: normal;
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .additonal-showas-icons {
            .material-icons {
                line-height: normal;
                padding: 0;
                display: block;
                width: 30px;
                height: 30px;
            }
        }

        .showas-icons .fa {
            font-size: 31px;
            vertical-align: middle;
            padding: 0px;
            border-radius: 100%;
            display: block;
            float: right;
        }
    }

    .navbar-toggle {
        background: #525050;
        border-radius: 0;
        color: #fff;
        margin: 0;
        margin-right: 10px;
        height: 45px;

        .icon-bar {
            background-color: #fff !important;
        }
    }

    .top-logo-wrapper {
        padding: 0;
        justify-content: flex-start;
    }

    .top-logo-wrapper {
        img {
            vertical-align: middle;
            display: block;
            position: relative;
            top: 2px;
            width: auto;
            max-width: auto;
            height: auto;
            max-height: 35px;
        }
    }

    .cms-index-index .carousel-primary-1 .slick-prev {
        background: url("../images/new-slick-arrows.gif") no-repeat 0 0;
        height: 43px;
        width: 23px;
        left: 35px;
        z-index: 99;
        background-size: cover;
    }

    .cms-index-index .carousel-primary-1 .slick-next {
        background: url("../images/new-slick-arrows.gif") no-repeat -23px 0;
        height: 43px;
        width: 23px;
        right: 35px;
        background-size: cover;
        z-index: 99;
    }

    .slideshow-main {
        margin-bottom: 20px;
    }

    .nopadd {
        padding: 0;
    }

    .-collapsed-target {
        margin-bottom: 20px;
    }

    .io-product-blocks {
        margin-top: 0 !important;
    }

    .ioacf-product-block-image img {
        width: 100%;
    }

    .homepage-block.newsletter {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .ioacf-blocks .block-title h4 {
        font-size: 33px;
        font-family: 'Oswald', san-serif;
        text-transform: uppercase;
        color: #333333;
        margin-top: 10px !important;
        margin-bottom: 20px !important;
        ;
        padding-top: 20px !important;
        ;
        border-top: 1px solid #eaeaea;
    }

    .homepage-content-block-container h1 {
        font-size: 19px;
    }

    .footer-block.newsletter .icon {
        font-size: 36px;
        width: 40px;
    }

    .footer-block.newsletter {
        margin-top: 20px;
        margin-bottom: 0px;
        padding: 0;
    }

    .footer-block.newsletter .title-icon-container>div {
        display: inline-block;
        vertical-align: top;
        max-width: 69%;
    }

    .footer-block.newsletter .icon {
        font-size: 40px;
        width: 17%;
    }

    .footer-block.newsletter .title {
        font-size: 24px;
    }

    .footer-block.newsletter .subtitle {
        font-size: 10px;
    }

    .footer-block.newsletter .form-group {
        width: 100%;
    }

    .footer-block.newsletter .form-group .input-group input {
        width: 100% !important;
    }

    .footer-block.newsletter .btn-primary {
        display: block !important;
        float: none;
        width: 100%;
        border-radius: 0 !important;
    }

    .page-footer .footer-methods h4 {
        display: block;
        vertical-align: top;
        font-size: 19px;
        font-weight: 600;
        margin-right: 0px;
        text-align: center;
    }

    .footer-methods {
        text-align: center;
    }

    .page-footer-before {
        margin-top: 20px;
        padding-bottom: 0;
    }

    .page-footer {
        padding-top: 10px;
        padding-bottom: 20px;

        .footer-social h4 {
            display: block;
            vertical-align: top;
            font-size: 21px;
            font-weight: 600;
            margin-right: 10px;
            text-transform: uppercase;
            font-family: 'Oswald', sans-serif;
            text-align: center;
        }

        .footer-social {
            padding-top: 10px;
            padding-bottom: 20px;
            text-align: center;
        }

        .footer-methods .payment-link {
            padding: 5px;
            min-width: 43px;
            min-height: 40px;
            font-size: 22px;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
            border-radius: 100%;
            margin-left: 6px;
        }
    }

    .media-right,
    .media>.pull-right {
        float: none !important;
        width: 100%;
        padding: 0;
    }

    .catalog-product-view .product.media.col-xs-12.col-md-6 {
        border: none;
        padding: 0;
        min-height: unset;
    }

    .catalogsearch-result-index,
    .catalog-category-view {
        .category-page-children {
            padding: 0;
            margin: auto;
        }
    }

    .catalog-product-view .product-info-main {
        border: none;
        min-height: unset;
        padding: 0;
    }

    .catalog-product-view .box-tocart .actions,
    .catalog-product-view .box-tocart .actions .btn {
        width: 100%;
    }

    .product-info-main .towishlist {
        display: inline-block;
        float: right;
        margin-left: 10px;
        width: 100%;
        margin-top: 20px;
    }

    .catalog-product-view .product-view-tabs .panel-heading a .rating {
        margin-left: 0;
        font-size: 18px;
        vertical-align: middle;
        display: block;
        margin-top: 10px;
    }

    .checkout-index-index {
        .opc-progress-bar {
            margin: 0;
            counter-reset: i;
            font-size: 0;
            padding: 0;
        }

        .opc-estimated-wrapper {
            display: none;
        }

        #checkoutSteps {
            list-style: none;
            padding: 0;
        }

        .field-tooltip .field-tooltip-action {
            display: none;
        }

        .modal-custom {
            clear: both;
            overflow: hidden;
            position: relative;
        }
    }

    .product-image-container,
    .product-image-wrapper,
    .product-image-wrapper img {
        width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        height: auto !important;
    }

    .button-quickview {
        display: none !important;
    }

    .cart-container .cart-summary {
        padding-top: 20px;
    }

    .cart.table-wrapper .col.item,
    .cart.table-wrapper .col.qty,
    .cart.table-wrapper .col.price,
    .cart.table-wrapper .col.subtotal,
    .cart.table-wrapper .col.msrp {
        box-sizing: border-box;
        float: none;
        text-align: center;
        white-space: nowrap;
        width: 100%;
        display: block;
        line-height: normal;
        clear: both;
        overflow: hidden;
    }

    .cart.table-wrapper .item .col.item {
        padding-bottom: 20px;
    }

    .cart-container .form-cart {
        width: 100%;
        float: none;
        order: 1;
        padding-right: 0;
        position: relative;
        clear: both;
    }

    .col.price .price,
    .col.subtotal .price {
        line-height: normal;
    }

    .cart.table-wrapper .product-item-details {
        white-space: normal;
        padding-bottom: 20px;
    }

    .cart.table-wrapper .product-item-details {
        white-space: normal;
        padding-bottom: 20px;
    }

    .authentication-wrapper {
        display: none;
    }

    .checkout-index-index .minicart-items .product-image-container {
        margin-bottom: 20px;
    }

    .checkout-index-index .minicart-items .product-item-details {
        padding: 0;
        margin-top: 10px;
    }

    .wishlist-index-index .wishlist-item .wishlist-item-inner .product-item-actions .btn {

        width: 100% !important;
        padding: 10px 26px !important;
        float: none;
        display: block;
        overflow: hidden;
        margin: 0;
    }
}

@media (max-width: 400px) {
    .top-logo-wrapper {
        img {
            display: none;
        }
    }
}

@media (min-device-width : 375px) and (max-device-width : 667px) {

    .product-image-container,
    .product-image-wrapper,
    .product-image-wrapper img {
        width: 100% !important;
        display: block;
        overflow: hidden;
        height: auto;
    }

    .col.price .price,
    .col.subtotal .price {
        line-height: normal;
    }

    .wishlist-index-index .actions-toolbar .btn {
        width: 100%;
        text-align: center;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .top-logo-wrapper {
        padding-left: 0px;
    }
}
