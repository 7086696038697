.account {
	.column.main{
		.block:not(.widget) {
			margin-bottom:50px;
			.block-title {
				border-bottom: 1px solid #c6c6c6;
				margin-bottom: 25px;
				padding-bottom: 10px;
				.action{
					margin-left: 15px;
				}
				strong{
					font-size: 2.2rem;
					font-weight: 300;				
				}
			}
			.box-title {
				margin: 0 0 10px;
				display: block;
				span {
					font-size: 1.6rem;
				}
			}
		}
	}
	.current > strong {
		padding: 10px 15px;
		display: block;
	}
}

.login-container {

	.block-title {
		margin-bottom: 20px;
	}

	.block-customer-login {

		background: #f3f3f3;
		padding: 40px;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;

		label {
			display: inline-block;
			max-width: 100%;
			margin-top: 10px;
			font-weight: 200;

			em {
				padding-right: 5px;
				margin: 0;
				color: #ff0000;
				font-size: 12px;
				vertical-align: middle;
			}
		}

		input {
			border: 1px solid #b3b3b3;
			border-radius: 0;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			transition: 0.4s;
			-webkit-transition: 0.4s;
			-moz-transition: 0.4s;
			&:focus {
				outline: none;
				box-shadow: none;
				border-color: black;
			}
		}
		textarea {
			transition: 0.4s;
			-webkit-transition: 0.4s;
			-moz-transition: 0.4s;
			&:focus {
				outline: none;
				box-shadow: none;
				border-color: black;
			}
		}
		select {
			transition: 0.4s;
			-webkit-transition: 0.4s;
			-moz-transition: 0.4s;
			&:focus {
				outline: none;
				box-shadow: none;
				border-color: black;
			}
		}

		button {

			color: #fff;
			text-transform: uppercase;
			font-weight: 600;
			float: right;
			margin-top: 20px;
			background: #007096;
			transition: 0.4s;
			-webkit-transition: 0.4s;
			-moz-transition: 0.4s;
			text-decoration: none;
			&:hover {
				background: #ff7300;
				text-decoration: none;
			}
			&:focus {
				background: #ff7300;
				text-decoration: none;
			}
			&:active {
				background: #ff7300;
				text-decoration: none;
			}
		}
	}

	.block-new-customer {

		background: #fff;
		padding: 40px;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;

		a {
			&.button {
				color: #fff;
				text-transform: uppercase;
				font-weight: 600;
				float: right;
				margin-top: 20px;
				background: #007096;
				transition: 0.4s;
				-webkit-transition: 0.4s;
				-moz-transition: 0.4s;
				text-decoration: none;
				width: 200px;
				text-align: center;
				padding: 10px;
				border-radius: 4px;
				&:hover {
					background: #ff7300;
					text-decoration: none;
				}
				&:focus {
					background: #ff7300;
					text-decoration: none;
				}
				&:active {
					background: #ff7300;
					text-decoration: none;
				}
			}
		}

	}

}
	
.wishlist-index-index {
    .products-grid.wishlist ol.product-items {
        padding: 0;
        list-style: none;

        li.product-item {
            max-width: 240px;
        }
    }

    .product-item-name {
        display: block;
    }

    .product-item-info {
        padding-bottom: 20px;
        margin-bottom: 25px;
        border-bottom: 1px solid #ddd;
    }

    .actions-toolbar button {
        background: #1ca5fb !important;
        border-color: #1ca5fb !important;
        padding: 11px 10px;
        color: #ffffff !important;
        font-family: 'Lato' !important;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        border: none;
    }
}

.table {
	&>thead,
	&>tbody {
		& > tr {
			th,
			td {
				&.empty {
					padding: 0px;
				}
			}
		}
	}
}

//	
//  Desktop	
//  _____________________________________________	
@media (min-width: 993px) {	
	.only-mobile-and-tablet {	
		display: none;	
	}	
}

//	
//  Desktop	
//  _____________________________________________	
@media (min-width: 1201px) {	
	.only-xl-tablet {	
		display: none;
	}
}

//	
//  Tablet xl	
//  _____________________________________________	
@media (max-width: 1200px) {	
	.only-xl-desktop {	
		display: none;	
	}
}

//	
//  Tablet xl	
//  _____________________________________________	
@media (max-width: 992px) {	
	.only-desktop {	
		display: none;	
	}	
}

