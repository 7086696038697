body {
    .table {
        & > thead > tr > th {
            border-bottom: 0px;
        }

        & > tbody + tbody {
            border-top: 1px;
        }
    }
}
