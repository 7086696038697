/**
 *
 **/
/* General Post Styles */
/* Comments */
/* Widgets */
.alignleft {
    display: inline;
    float: left;
    margin-right: 2.5em;
    margin-bottom: 2.5em;
}

.alignright {
    display: inline;
    float: right;
    margin-left: 2.5em;
    margin-bottom: 2.5em;
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

ol.post-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

ol.comment-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.post-list {
    li {
        &:after {
            content: ' ';
            clear: both;
            display: block;
        }
    }
}

.post-entry {
    overflow: hidden;
    margin: 0 0 20px;

    iframe {
        max-width: 100%;
    }
}

.post-date {
    margin: 0 0 20px;
}

.post-image {
    float: left;
    margin-right: 10px;
}

.post-meta {
    clear: both;
    margin: 0 0 20px;
    text-align: left;

    .post-meta-item {
        display: inline-block;
        margin-right: 10px;
    }
}

.post-readmore {
    margin-top: 10px;
}

.comment-list-item {
    margin: 0 0 20px;
}

.comment-list.children {
    margin-top: 20px;
}

.comment-image {
    float: left;
    margin: 0 10px 10px 0;
}

.comment-author {
    font-weight: bold;
    display: block;
}

.comment-entry {
    clear: both;
    margin: 0 0 20px;
}

.block-blog {
    .block-title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #c6c6c6;

        >strong {
            font-size: 18px;
            font-weight: 300;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    ol {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.block-blog-cloud {
    li {
        display: inline;
        margin: 0;
    }
}

.block-blog-categories {
    ul.level1 {
        margin-top: 1rem;
    }
}
