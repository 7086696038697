@import "https://fonts.googleapis.com/css?family=Open+Sans:400,700";

.megemenu-container {
    background: #007096;
}

.navbar {
    min-height: 60px;
    line-height: 60px;
}

.navbar-mainnavigation {
    background: #fff none repeat scroll 0 0;
    margin-top: 51px;
}

.iomegamenu {
    .navbar-nav {
        >li {
            >.dropdown-menu {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:hover {
                >.dropdown-menu {
                    display: block;
                    display: block;
                }
            }
        }
    }

    .navbar-megamenu {
        .navbar-nav {
            >li {
                border-left: 1px solid #1880a3;
                position: unset;
                flex-grow: 1;
                height: 100%;

                &:hover {
                    >a {
                        font-size: 14px;
                        font-weight: 700;
                        padding: 6px 10px;
                        text-transform: uppercase;
                        color: #fff;
                        margin-top: 14px;
                        margin-bottom: 12px;
                    }
                }

                >a {
                    font-size: 14px;
                    font-weight: 700;
                    padding: 6px 10px;
                    text-transform: uppercase;
                    color: #fff;
                    margin-top: 14px;
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;

                    .glyphicon {
                        line-height: normal;
                        margin-left: 10px;
                        line-height: normal;
                        margin-left: 10px;
                    }
                }

                &:last-child {
                    border-right: 1px solid #1880a3;
                }

                &:first-of-type {
                    >a {
                        border-left: none !important;
                    }
                }

                &:first-child {
                    &:hover {
                        a {
                            border-left: none;
                        }
                    }

                    a {
                        border-left: none;
                    }
                }
            }

            >li.dropdown {
                &:hover {
                    >a {
                        z-index: 2000;
                        background: #fff;
                        position: relative;
                        z-index: 2000;
                        background: none !important;
                    }

                    a {
                        border-top-color: transparent;
                        border-left-color: transparent;
                        border-right-color: transparent;
                    }
                }

                &:focus {
                    border-top-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;

                    >a {
                        padding-left: 14px;
                        z-index: 2000;
                        background: none !important;
                    }

                    a {
                        border-top-color: transparent;
                        border-left-color: transparent;
                        border-right-color: transparent;
                    }
                }
            }

            >li.open {
                &:hover {
                    .dropdown-menu {
                        display: block !important;
                        display: block;
                    }
                }

                .dropdown-menu {
                    display: block !important;
                    display: none;
                }
            }
        }
    }

    .nav {
        >li {
            >a {
                &:hover {
                    background-color: transparent;
                    border-color: transparent;
                    background-color: transparent;
                    border-color: transparent;
                }

                &:focus {
                    background-color: transparent;
                    border-color: transparent;
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }

        .open {
            >a {
                background-color: transparent;
                border-color: transparent;
                background-color: transparent;
                border-color: transparent;

                &:hover {
                    background-color: transparent;
                    border-color: transparent;
                    background-color: transparent;
                    border-color: transparent;
                }

                &:focus {
                    background-color: transparent;
                    border-color: transparent;
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }
    }

    .mega-dropdown-menu {
        padding: 10px 0 !important;
        width: auto;
        box-shadow: none;
        -webkit-box-shadow: none;
        padding: 20px 0px;
        width: auto;
        box-shadow: none;
        -webkit-box-shadow: none;

        >li {
            >ul {
                padding: 15px;
                margin: 0;
                padding: 15px;
                margin: 0;

                >li {
                    display: block;
                    float: none;
                    text-align: left;
                    font-size: 14px;
                    margin: 0;
                    line-height: normal;
                    list-style: none;
                    display: block;
                    float: none;
                    text-align: left;
                    font-size: 14px;
                    margin: 0;
                    line-height: normal;
                    list-style: none;

                    >a {
                        display: block;
                        padding: 3px 20px;
                        clear: both;
                        font-weight: normal;
                        line-height: 1.428571429;
                        color: #999;
                        white-space: normal;
                        display: block;
                        padding: 3px 20px;
                        clear: both;
                        font-weight: normal;
                        line-height: 1.428571429;
                        color: #999;
                        white-space: normal;
                    }
                }
            }

            ul {
                >li {
                    >a {
                        &:hover {
                            text-decoration: none;
                            color: #444;
                            background-color: #f5f5f5;
                            text-decoration: none;
                            color: #444;
                            background-color: #f5f5f5;
                        }

                        &:focus {
                            text-decoration: none;
                            color: #444;
                            background-color: #f5f5f5;
                            text-decoration: none;
                            color: #444;
                            background-color: #f5f5f5;
                        }
                    }
                }
            }
        }

        .dropdown-header {
            display: none !important;
            color: #428bca;
            font-size: 18px;
            font-weight: bold;
        }

        form {
            margin: 3px 20px;
            margin: 3px 20px;
        }

        .form-group {
            margin-bottom: 3px;
            margin-bottom: 3px;
        }
    }
}

.view-all-container {
    a {
        text-align: center;
        padding: 10px;
        border: 1px solid;
        margin-top: 20px;
        border-color: #ccc;
        overflow: hidden;
        display: none;
    }
}

.menu-static-top {
    margin-bottom: 10px;
}

.menu-static-bottom {
    margin-top: 10px;
}

@media (max-width: 992px) {
    .iomegamenu {
        .mega-dropdown {
            width: 100%;
        }

        .navbar-megamenu {
            .navbar-nav {
                & > li {
                    & > a {
                        justify-content: flex-start;

                        .dropdown-title {
                            flex-grow: 1;
                        }
                    }

                    &.mega-dropdown.open {
                        & > a {
                            .glyphicon {
                                &::before {
                                    content: "\e113";
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .menulist {
        display: inline-block;
        vertical-align: top;
        padding: 0 15px;
        padding: 0;
        list-style: none;

        ul {
            padding: 0 15px;
        }
    }

    .menu-static-right {
        display: inline-block;
        vertical-align: top;
        padding: 0 15px;
        padding: 0 15px;

        img {
            width: 48%;

            + {
                img {
                    width: 48%;
                    margin-left: 15px;
                }
            }
        }
    }

    .menu-static-left {
        display: inline-block;
        vertical-align: top;
        padding: 0 15px;
        padding: 0 15px;

        img {
            width: 48%;

            + {
                img {
                    width: 48%;
                    margin-left: 15px;
                }
            }
        }
    }

    li.without-heading {
        padding-left: 25px;
        padding-right: 15px;
    }

    .iomegamenu {
        .navbar-nav {
            >li {
                &:hover {
                    >.dropdown-menu {
                        width: 100%;
                    }
                }
            }
        }
    }

    .menulist.static-right {
        width: 50%;
    }

    .menulist.static-left {
        width: 50%;
    }

    .static-right {
        + {
            .menu-static-right {
                width: 49%;
                float: right;
                padding: 0 15px;
            }
        }
    }

    .static-left {
        + {
            .menu-static-left {
                width: 49%;
                float: left;
                padding: 0 15px;
            }
        }
    }

    .view-all-container {
        display: none;
        clear: both;
        overflow: hidden;
    }

    .navbar-collapse.collapse {
        display: table;
        margin: 0 auto !important;
        text-align: center;
    }

    .level1 {
        >ul {
            >li {
                >a {
                    padding: 5px 0;
                    font-weight: normal !important;
                    text-transform: none !important;
                }
            }
        }
    }

    .level2 {
        >a {
            padding: 0 !important;
        }
    }

    .menuheading {
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #333333;
    }

    .megamenu-viewall {
        text-align: center;
    }

    .navbar-megamenu {
        margin-bottom: 0;
    }
}
