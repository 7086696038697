.block-collapsible-nav {
    .content {
        background: #f5f5f5;
        padding: 15px 0;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a {
            border-left: 3px solid transparent;
            color: #575757;
            display: block;
            padding: 5px 18px 5px 15px;
            text-decoration: none;

            &:hover {
                background: #e8e8e8;
            }
        }

        >strong {
            border-left: 3px solid transparent;
            color: #575757;
            display: block;
            padding: 5px 18px 5px 15px;
        }

        .delimiter {
            border-top: 1px solid #d1d1d1;
            display: block;
            margin: 10px 1.8rem;
        }
    }

    .item.current {
        a,
        &>strong {
            border-color: #ff5501;
            color: #000000;
            font-weight: 600;
        }
    }
}

@media only screen and (max-width: 768px) {
    .account .column.main {
        padding-top: 41px;
        position: relative;
    }

    .block-collapsible-nav {
        left: 0;
        position: absolute;
        top: -21px;
        width: 100%;
        z-index: 5;

        .title {
            border-top: 1px solid #cccccc;
            cursor: pointer;
            margin-bottom: 0;
            position: relative;
            border-bottom: 1px solid #cccccc;
            padding: 10px 40px 10px 15px;
            display: block;
            text-decoration: none;

            &:after {
                position: absolute;
                right: 20px;
                top: 10px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 12px;
                line-height: inherit;
                color: inherit;
                content: '\e622';
                font-family: 'luma-icons';
                margin: 3px 0 0 0;
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }

        .title.active {
            &:after {
                content: '\e621';
            }
        }

        .content {
            border-bottom: 1px solid #cccccc;
            display: none;
        }

        .content.active {
            display: block;
        }
    }
}

@media all and (min-width: 769px),
print {
    .block-collapsible-nav {
        .title {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }
}
