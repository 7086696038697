.homepage-block.newsletter {
    margin-top: 50px;
    margin-bottom: 40px;

    .newsletter-agree-cb,
    .newsletter-agree-state {
        display: inline-block;
        font-size: 10px;
        max-width: 80%;
        vertical-align: top;
        line-height: 12px;
        margin-top: 10px;

        a {
            border-bottom: 1px dotted;
        }
    }

    .title {
        color: #000;
        letter-spacing: 1.2px;
        margin-bottom: 0;
        line-height: 28px;
        margin-left: 11px;
        font-family: 'Oswald', sans-serif;
        font-size: 25px;
        font-weight: 500;
    }

    .fa {
        color: #47c5b7;
        font-size: 54px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .subtitle {
        font-size: 11px;
        letter-spacing: 0.2px;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 15px;
        display: block;
        margin-left: 10px;
        font-family: Oswald;
    }

    .content {
        .field.newsletter {
            position: relative;
            top: 8px;
        }
    }

    .newsletter-agree-state {
        margin-left: 5px;
        font-family: 'Oswald', sans-serif;
    }

    button.subscribe {
        background: #28bdb7 !important;
        border-color: #28bdb7 !important;
        border-radius: 0;
        display: block;
        width: 100%;
        margin-top: 10px;
    }

    .form-group {
        width: 100%;

        .input-group {
            width: 100%;

            input {
                width: 100%;
                font-size: 13px;
                padding-top: 10px;
                height: auto;
                padding-bottom: 10px;
                padding-left: 15px;
                border-color: #000;
            }
        }
    }
}

.blockcategory_blocks-block .ioacf-block {
    margin-bottom: 30px;
}

.calltoaction-container {
    background: #cce2ea;
}
