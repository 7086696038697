$space-1: 0.5rem !default;
$space-2: 1rem !default;
$space-3: 1.5rem !default;
$space-4: 2rem !default;
$space-5: 2.5rem !default;
$space-6: 3rem !default;
$space-7: 3.5rem !default;
$space-8: 4rem !default;
$space-8: 4.5rem !default;
$space-9: 5rem !default;
$space-10: 5.5rem !default;

// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Responsive Margin */

@media #{$breakpoint-sm} {

  .sm-m0 {
    margin: 0
  }
  .sm-mt0 {
    margin-top: 0
  }
  .sm-mr0 {
    margin-right: 0
  }
  .sm-mb0 {
    margin-bottom: 0
  }
  .sm-ml0 {
    margin-left: 0
  }
  .sm-mx0 {
    margin-left: 0;
    margin-right: 0
  }
  .sm-my0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .sm-m1 {
    margin: $space-1
  }
  .sm-mt1 {
    margin-top: $space-1
  }
  .sm-mr1 {
    margin-right: $space-1
  }
  .sm-mb1 {
    margin-bottom: $space-1
  }
  .sm-ml1 {
    margin-left: $space-1
  }
  .sm-mx1 {
    margin-left: $space-1;
    margin-right: $space-1
  }
  .sm-my1 {
    margin-top: $space-1;
    margin-bottom: $space-1
  }

  .sm-m2 {
    margin: $space-2
  }
  .sm-mt2 {
    margin-top: $space-2
  }
  .sm-mr2 {
    margin-right: $space-2
  }
  .sm-mb2 {
    margin-bottom: $space-2
  }
  .sm-ml2 {
    margin-left: $space-2
  }
  .sm-mx2 {
    margin-left: $space-2;
    margin-right: $space-2
  }
  .sm-my2 {
    margin-top: $space-2;
    margin-bottom: $space-2
  }

  .sm-m3 {
    margin: $space-3
  }
  .sm-mt3 {
    margin-top: $space-3
  }
  .sm-mr3 {
    margin-right: $space-3
  }
  .sm-mb3 {
    margin-bottom: $space-3
  }
  .sm-ml3 {
    margin-left: $space-3
  }
  .sm-mx3 {
    margin-left: $space-3;
    margin-right: $space-3
  }
  .sm-my3 {
    margin-top: $space-3;
    margin-bottom: $space-3
  }

  .sm-m4 {
    margin: $space-4
  }
  .sm-mt4 {
    margin-top: $space-4
  }
  .sm-mr4 {
    margin-right: $space-4
  }
  .sm-mb4 {
    margin-bottom: $space-4
  }
  .sm-ml4 {
    margin-left: $space-4
  }
  .sm-mx4 {
    margin-left: $space-4;
    margin-right: $space-4
  }
  .sm-my4 {
    margin-top: $space-4;
    margin-bottom: $space-4
  }


  .sm-m5 {
    margin: $space-5
  }
  .sm-mt5 {
    margin-top: $space-5
  }
  .sm-mr5 {
    margin-right: $space-5
  }
  .sm-mb5 {
    margin-bottom: $space-5
  }
  .sm-ml5 {
    margin-left: $space-5
  }
  .sm-mx5 {
    margin-left: $space-5;
    margin-right: $space-5
  }
  .sm-my5 {
    margin-top: $space-5;
    margin-bottom: $space-5
  }

  .sm-m6 {
    margin: $space-6
  }
  .sm-mt6 {
    margin-top: $space-6
  }
  .sm-mr6 {
    margin-right: $space-6
  }
  .sm-mb6 {
    margin-bottom: $space-6
  }
  .sm-ml6 {
    margin-left: $space-6
  }
  .sm-mx6 {
    margin-left: $space-6;
    margin-right: $space-6
  }
  .sm-my6 {
    margin-top: $space-6;
    margin-bottom: $space-6
  }

  .sm-m7 {
    margin: $space-7
  }
  .sm-mt7 {
    margin-top: $space-7
  }
  .sm-mr7 {
    margin-right: $space-7
  }
  .sm-mb7 {
    margin-bottom: $space-7
  }
  .sm-ml7 {
    margin-left: $space-7
  }
  .sm-mx7 {
    margin-left: $space-7;
    margin-right: $space-7
  }
  .sm-my7 {
    margin-top: $space-7;
    margin-bottom: $space-7
  }

  .sm-m8 {
    margin: $space-8
  }
  .sm-mt8 {
    margin-top: $space-8
  }
  .sm-mr8 {
    margin-right: $space-8
  }
  .sm-mb8 {
    margin-bottom: $space-8
  }
  .sm-ml8 {
    margin-left: $space-8
  }
  .sm-mx8 {
    margin-left: $space-8;
    margin-right: $space-8
  }
  .sm-my8 {
    margin-top: $space-8;
    margin-bottom: $space-8
  }

  .sm-m9 {
    margin: $space-9
  }
  .sm-mt9 {
    margin-top: $space-9
  }
  .sm-mr9 {
    margin-right: $space-9
  }
  .sm-mb9 {
    margin-bottom: $space-9
  }
  .sm-ml9 {
    margin-left: $space-9
  }
  .sm-mx9 {
    margin-left: $space-9;
    margin-right: $space-9
  }
  .sm-my9 {
    margin-top: $space-9;
    margin-bottom: $space-9
  }

  .sm-m10 {
    margin: $space-10
  }
  .sm-mt10 {
    margin-top: $space-10
  }
  .sm-mr10 {
    margin-right: $space-10
  }
  .sm-mb10 {
    margin-bottom: $space-10
  }
  .sm-ml10 {
    margin-left: $space-10
  }
  .sm-mx10 {
    margin-left: $space-10;
    margin-right: $space-10
  }
  .sm-my10 {
    margin-top: $space-10;
    margin-bottom: $space-10
  }

  .sm-mxn1 {
    margin-left: -$space-1;
    margin-right: -$space-1
  }
  .sm-mxn2 {
    margin-left: -$space-2;
    margin-right: -$space-2
  }
  .sm-mxn3 {
    margin-left: -$space-3;
    margin-right: -$space-3
  }
  .sm-mxn4 {
    margin-left: -$space-4;
    margin-right: -$space-4
  }
  .sm-mxn5 {
    margin-left: -$space-5;
    margin-right: -$space-5
  }
  .sm-mxn6 {
    margin-left: -$space-6;
    margin-right: -$space-6
  }
  .sm-mxn7 {
    margin-left: -$space-7;
    margin-right: -$space-7
  }
  .sm-mxn8 {
    margin-left: -$space-8;
    margin-right: -$space-8
  }
  .sm-mxn9 {
    margin-left: -$space-9;
    margin-right: -$space-9
  }
  .sm-mxn10 {
    margin-left: -$space-10;
    margin-right: -$space-10
  }

  .sm-ml-auto {
    margin-left: auto
  }
  .sm-mr-auto {
    margin-right: auto
  }
  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto
  }

}

@media #{$breakpoint-md} {

  .md-m0 {
    margin: 0
  }
  .md-mt0 {
    margin-top: 0
  }
  .md-mr0 {
    margin-right: 0
  }
  .md-mb0 {
    margin-bottom: 0
  }
  .md-ml0 {
    margin-left: 0
  }
  .md-mx0 {
    margin-left: 0;
    margin-right: 0
  }
  .md-my0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md-m1 {
    margin: $space-1
  }
  .md-mt1 {
    margin-top: $space-1
  }
  .md-mr1 {
    margin-right: $space-1
  }
  .md-mb1 {
    margin-bottom: $space-1
  }
  .md-ml1 {
    margin-left: $space-1
  }
  .md-mx1 {
    margin-left: $space-1;
    margin-right: $space-1
  }
  .md-my1 {
    margin-top: $space-1;
    margin-bottom: $space-1
  }

  .md-m2 {
    margin: $space-2
  }
  .md-mt2 {
    margin-top: $space-2
  }
  .md-mr2 {
    margin-right: $space-2
  }
  .md-mb2 {
    margin-bottom: $space-2
  }
  .md-ml2 {
    margin-left: $space-2
  }
  .md-mx2 {
    margin-left: $space-2;
    margin-right: $space-2
  }
  .md-my2 {
    margin-top: $space-2;
    margin-bottom: $space-2
  }

  .md-m3 {
    margin: $space-3
  }
  .md-mt3 {
    margin-top: $space-3
  }
  .md-mr3 {
    margin-right: $space-3
  }
  .md-mb3 {
    margin-bottom: $space-3
  }
  .md-ml3 {
    margin-left: $space-3
  }
  .md-mx3 {
    margin-left: $space-3;
    margin-right: $space-3
  }
  .md-my3 {
    margin-top: $space-3;
    margin-bottom: $space-3
  }

  .md-m4 {
    margin: $space-4
  }
  .md-mt4 {
    margin-top: $space-4
  }
  .md-mr4 {
    margin-right: $space-4
  }
  .md-mb4 {
    margin-bottom: $space-4
  }
  .md-ml4 {
    margin-left: $space-4
  }
  .md-mx4 {
    margin-left: $space-4;
    margin-right: $space-4
  }
  .md-my4 {
    margin-top: $space-4;
    margin-bottom: $space-4
  }

  .md-mxn1 {
    margin-left: -$space-1;
    margin-right: -$space-1;
  }
  .md-mxn2 {
    margin-left: -$space-2;
    margin-right: -$space-2;
  }
  .md-mxn3 {
    margin-left: -$space-3;
    margin-right: -$space-3;
  }
  .md-mxn4 {
    margin-left: -$space-4;
    margin-right: -$space-4;
  }

  .md-ml-auto {
    margin-left: auto
  }
  .md-mr-auto {
    margin-right: auto
  }
  .md-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

}

@media #{$breakpoint-lg} {

  .lg-m0 {
    margin: 0
  }
  .lg-mt0 {
    margin-top: 0
  }
  .lg-mr0 {
    margin-right: 0
  }
  .lg-mb0 {
    margin-bottom: 0
  }
  .lg-ml0 {
    margin-left: 0
  }
  .lg-mx0 {
    margin-left: 0;
    margin-right: 0
  }
  .lg-my0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .lg-m1 {
    margin: $space-1
  }
  .lg-mt1 {
    margin-top: $space-1
  }
  .lg-mr1 {
    margin-right: $space-1
  }
  .lg-mb1 {
    margin-bottom: $space-1
  }
  .lg-ml1 {
    margin-left: $space-1
  }
  .lg-mx1 {
    margin-left: $space-1;
    margin-right: $space-1
  }
  .lg-my1 {
    margin-top: $space-1;
    margin-bottom: $space-1
  }

  .lg-m2 {
    margin: $space-2
  }
  .lg-mt2 {
    margin-top: $space-2
  }
  .lg-mr2 {
    margin-right: $space-2
  }
  .lg-mb2 {
    margin-bottom: $space-2
  }
  .lg-ml2 {
    margin-left: $space-2
  }
  .lg-mx2 {
    margin-left: $space-2;
    margin-right: $space-2
  }
  .lg-my2 {
    margin-top: $space-2;
    margin-bottom: $space-2
  }

  .lg-m3 {
    margin: $space-3
  }
  .lg-mt3 {
    margin-top: $space-3
  }
  .lg-mr3 {
    margin-right: $space-3
  }
  .lg-mb3 {
    margin-bottom: $space-3
  }
  .lg-ml3 {
    margin-left: $space-3
  }
  .lg-mx3 {
    margin-left: $space-3;
    margin-right: $space-3
  }
  .lg-my3 {
    margin-top: $space-3;
    margin-bottom: $space-3
  }

  .lg-m4 {
    margin: $space-4
  }
  .lg-mt4 {
    margin-top: $space-4
  }
  .lg-mr4 {
    margin-right: $space-4
  }
  .lg-mb4 {
    margin-bottom: $space-4
  }
  .lg-ml4 {
    margin-left: $space-4
  }
  .lg-mx4 {
    margin-left: $space-4;
    margin-right: $space-4
  }
  .lg-my4 {
    margin-top: $space-4;
    margin-bottom: $space-4
  }

  .lg-mxn1 {
    margin-left: -$space-1;
    margin-right: -$space-1;
  }
  .lg-mxn2 {
    margin-left: -$space-2;
    margin-right: -$space-2;
  }
  .lg-mxn3 {
    margin-left: -$space-3;
    margin-right: -$space-3;
  }
  .lg-mxn4 {
    margin-left: -$space-4;
    margin-right: -$space-4;
  }

  .lg-ml-auto {
    margin-left: auto
  }
  .lg-mr-auto {
    margin-right: auto
  }
  .lg-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

}

/*
 * Padding Options
 */

$breakpoint-sm: '(min-width: 30em)' !default;
$breakpoint-pd: '(min-width: 42em)' !default;
$breakpoint-lg: '(min-width: 54em)' !default;

@media #{$breakpoint-sm} {

  .sm-p0 {
    padding: 0
  }
  .sm-pt0 {
    padding-top: 0
  }
  .sm-pr0 {
    padding-right: 0
  }
  .sm-pb0 {
    padding-bottom: 0
  }
  .sm-pl0 {
    padding-left: 0
  }
  .sm-px0 {
    padding-left: 0;
    padding-right: 0
  }
  .sm-py0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .sm-p1 {
    margin: $space-1
  }
  .sm-pt1 {
    padding-top: $space-1
  }
  .sm-pr1 {
    padding-right: $space-1
  }
  .sm-pb1 {
    padding-bottom: $space-1
  }
  .sm-pl1 {
    padding-left: $space-1
  }
  .sm-px1 {
    padding-left: $space-1;
    padding-right: $space-1
  }
  .sm-py1 {
    padding-top: $space-1;
    padding-bottom: $space-1
  }

  .sm-p2 {
    margin: $space-2
  }
  .sm-pt2 {
    padding-top: $space-2
  }
  .sm-pr2 {
    padding-right: $space-2
  }
  .sm-pb2 {
    padding-bottom: $space-2
  }
  .sm-pl2 {
    padding-left: $space-2
  }
  .sm-px2 {
    padding-left: $space-2;
    padding-right: $space-2
  }
  .sm-py2 {
    padding-top: $space-2;
    padding-bottom: $space-2
  }

  .sm-p3 {
    margin: $space-3
  }
  .sm-pt3 {
    padding-top: $space-3
  }
  .sm-pr3 {
    padding-right: $space-3
  }
  .sm-pb3 {
    padding-bottom: $space-3
  }
  .sm-pl3 {
    padding-left: $space-3
  }
  .sm-px3 {
    padding-left: $space-3;
    padding-right: $space-3
  }
  .sm-py3 {
    padding-top: $space-3;
    padding-bottom: $space-3
  }

  .sm-p4 {
    margin: $space-4
  }
  .sm-pt4 {
    padding-top: $space-4
  }
  .sm-pr4 {
    padding-right: $space-4
  }
  .sm-pb4 {
    padding-bottom: $space-4
  }
  .sm-pl4 {
    padding-left: $space-4
  }
  .sm-px4 {
    padding-left: $space-4;
    padding-right: $space-4
  }
  .sm-py4 {
    padding-top: $space-4;
    padding-bottom: $space-4
  }

  .sm-p5 {
    padding: $space-5
  }
  .sm-pt5 {
    padding-top: $space-5
  }
  .sm-pr5 {
    padding-right: $space-5
  }
  .sm-pb5 {
    padding-bottom: $space-5
  }
  .sm-pl5 {
    padding-left: $space-5
  }
  .sm-px5 {
    padding-left: $space-5;
    padding-right: $space-5
  }
  .sm-py5 {
    padding-top: $space-5;
    padding-bottom: $space-5
  }

  .sm-p6 {
    padding: $space-6
  }
  .sm-pt6 {
    padding-top: $space-6
  }
  .sm-pr6 {
    padding-right: $space-6
  }
  .sm-pb6 {
    padding-bottom: $space-6
  }
  .sm-pl6 {
    padding-left: $space-6
  }
  .sm-px6 {
    padding-left: $space-6;
    padding-right: $space-6
  }
  .sm-py6 {
    padding-top: $space-6;
    padding-bottom: $space-6
  }

  .sm-p7 {
    padding: $space-7
  }
  .sm-pt7 {
    padding-top: $space-7
  }
  .sm-pr7 {
    padding-right: $space-7
  }
  .sm-pb7 {
    padding-bottom: $space-7
  }
  .sm-pl7 {
    padding-left: $space-7
  }
  .sm-px7 {
    padding-left: $space-7;
    padding-right: $space-7
  }
  .sm-py7 {
    padding-top: $space-7;
    padding-bottom: $space-7
  }

  .sm-p8 {
    padding: $space-8
  }
  .sm-pt8 {
    padding-top: $space-8
  }
  .sm-pr8 {
    padding-right: $space-8
  }
  .sm-pb8 {
    padding-bottom: $space-8
  }
  .sm-pl8 {
    padding-left: $space-8
  }
  .sm-px8 {
    padding-left: $space-8;
    padding-right: $space-8
  }
  .sm-py8 {
    padding-top: $space-8;
    padding-bottom: $space-8
  }

  .sm-p9 {
    padding: $space-9
  }
  .sm-pt9 {
    padding-top: $space-9
  }
  .sm-pr9 {
    padding-right: $space-9
  }
  .sm-pb9 {
    padding-bottom: $space-9
  }
  .sm-pl9 {
    padding-left: $space-9
  }
  .sm-px9 {
    padding-left: $space-9;
    padding-right: $space-9
  }
  .sm-py9 {
    padding-top: $space-9;
    padding-bottom: $space-9
  }

  .sm-p10 {
    padding: $space-10
  }
  .sm-pt10 {
    padding-top: $space-10
  }
  .sm-pr10 {
    padding-right: $space-10
  }
  .sm-pb10 {
    padding-bottom: $space-10
  }
  .sm-pl10 {
    padding-left: $space-10
  }
  .sm-px10 {
    padding-left: $space-10;
    padding-right: $space-10
  }
  .sm-py10 {
    padding-top: $space-10;
    padding-bottom: $space-10
  }

  .sm-pxn1 {
    padding-left: -$space-1;
    padding-right: -$space-1
  }
  .sm-pxn2 {
    padding-left: -$space-2;
    padding-right: -$space-2
  }
  .sm-pxn3 {
    padding-left: -$space-3;
    padding-right: -$space-3
  }
  .sm-pxn4 {
    padding-left: -$space-4;
    padding-right: -$space-4
  }
  .sm-pxn5 {
    padding-left: -$space-5;
    padding-right: -$space-5
  }
  .sm-pxn6 {
    padding-left: -$space-6;
    padding-right: -$space-6
  }
  .sm-pxn5 {
    padding-left: -$space-7;
    padding-right: -$space-7
  }
  .sm-pxn5 {
    padding-left: -$space-8;
    padding-right: -$space-8
  }

  .sm-pxn5 {
    padding-left: -$space-9;
    padding-right: -$space-9
  }

  .sm-pxn5 {
    padding-left: -$space-10;
    padding-right: -$space-10
  }




  .sm-pl-auto {
    padding-left: auto
  }
  .sm-pr-auto {
    padding-right: auto
  }
  .sm-px-auto {
    padding-left: auto;
    padding-right: auto
  }

}

@media #{$breakpoint-pd} {

  .md-p0 {
    padding: 0
  }
  .md-pt0 {
    padding-top: 0
  }
  .md-pr0 {
    padding-right: 0
  }
  .md-pb0 {
    padding-bottom: 0
  }
  .md-pl0 {
    padding-left: 0
  }
  .md-px0 {
    padding-left: 0;
    padding-right: 0
  }
  .md-py0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .md-p1 {
    padding: $space-1
  }
  .md-pt1 {
    padding-top: $space-1
  }
  .md-pr1 {
    padding-right: $space-1
  }
  .md-pb1 {
    padding-bottom: $space-1
  }
  .md-pl1 {
    padding-left: $space-1
  }
  .md-px1 {
    padding-left: $space-1;
    padding-right: $space-1
  }
  .md-py1 {
    padding-top: $space-1;
    padding-bottom: $space-1
  }

  .md-p2 {
    padding: $space-2
  }
  .md-pt2 {
    padding-top: $space-2
  }
  .md-pr2 {
    padding-right: $space-2
  }
  .md-pb2 {
    padding-bottom: $space-2
  }
  .md-pl2 {
    padding-left: $space-2
  }
  .md-px2 {
    padding-left: $space-2;
    padding-right: $space-2
  }
  .md-py2 {
    padding-top: $space-2;
    padding-bottom: $space-2
  }

  .md-p3 {
    padding: $space-3
  }
  .md-pt3 {
    padding-top: $space-3
  }
  .md-pr3 {
    padding-right: $space-3
  }
  .md-pb3 {
    padding-bottom: $space-3
  }
  .md-pl3 {
    padding-left: $space-3
  }
  .md-px3 {
    padding-left: $space-3;
    padding-right: $space-3
  }
  .md-py3 {
    padding-top: $space-3;
    padding-bottom: $space-3
  }

  .md-p4 {
    padding: $space-4
  }
  .md-pt4 {
    padding-top: $space-4
  }
  .md-pr4 {
    padding-right: $space-4
  }
  .md-pb4 {
    padding-bottom: $space-4
  }
  .md-pl4 {
    padding-left: $space-4
  }
  .md-px4 {
    padding-left: $space-4;
    padding-right: $space-4
  }
  .md-py4 {
    padding-top: $space-4;
    padding-bottom: $space-4
  }

  .md-pxn1 {
    padding-left: -$space-1;
    padding-right: -$space-1;
  }
  .md-pxn2 {
    padding-left: -$space-2;
    padding-right: -$space-2;
  }
  .md-pxn3 {
    padding-left: -$space-3;
    padding-right: -$space-3;
  }
  .md-pxn4 {
    padding-left: -$space-4;
    padding-right: -$space-4;
  }

  .md-pl-auto {
    padding-left: auto
  }
  .md-pr-auto {
    padding-right: auto
  }
  .md-px-auto {
    padding-left: auto;
    padding-right: auto;
  }

}

@media #{$breakpoint-lg} {

  .lg-p0 {
    padding: 0
  }
  .lg-pt0 {
    padding-top: 0
  }
  .lg-pr0 {
    padding-right: 0
  }
  .lg-pb0 {
    padding-bottom: 0
  }
  .lg-pl0 {
    padding-left: 0
  }
  .lg-px0 {
    padding-left: 0;
    padding-right: 0
  }
  .lg-py0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .lg-p1 {
    padding: $space-1
  }
  .lg-pt1 {
    padding-top: $space-1
  }
  .lg-pr1 {
    padding-right: $space-1
  }
  .lg-pb1 {
    padding-bottom: $space-1
  }
  .lg-pl1 {
    padding-left: $space-1
  }
  .lg-px1 {
    padding-left: $space-1;
    padding-right: $space-1
  }
  .lg-py1 {
    padding-top: $space-1;
    padding-bottom: $space-1
  }

  .lg-p2 {
    padding: $space-2
  }
  .lg-pt2 {
    padding-top: $space-2
  }
  .lg-pr2 {
    padding-right: $space-2
  }
  .lg-pb2 {
    padding-bottom: $space-2
  }
  .lg-pl2 {
    padding-left: $space-2
  }
  .lg-px2 {
    padding-left: $space-2;
    padding-right: $space-2
  }
  .lg-py2 {
    padding-top: $space-2;
    padding-bottom: $space-2
  }

  .lg-p3 {
    padding: $space-3
  }
  .lg-pt3 {
    padding-top: $space-3
  }
  .lg-pr3 {
    padding-right: $space-3
  }
  .lg-pb3 {
    padding-bottom: $space-3
  }
  .lg-pl3 {
    padding-left: $space-3
  }
  .lg-px3 {
    padding-left: $space-3;
    padding-right: $space-3
  }
  .lg-py3 {
    padding-top: $space-3;
    padding-bottom: $space-3
  }

  .lg-p4 {
    padding: $space-4
  }
  .lg-pt4 {
    padding-top: $space-4
  }
  .lg-pr4 {
    padding-right: $space-4
  }
  .lg-pb4 {
    padding-bottom: $space-4
  }
  .lg-pl4 {
    padding-left: $space-4
  }
  .lg-px4 {
    padding-left: $space-4;
    padding-right: $space-4
  }
  .lg-py4 {
    padding-top: $space-4;
    padding-bottom: $space-4
  }

  .lg-pxn1 {
    padding-left: -$space-1;
    padding-right: -$space-1;
  }
  .lg-pxn2 {
    padding-left: -$space-2;
    padding-right: -$space-2;
  }
  .lg-pxn3 {
    padding-left: -$space-3;
    padding-right: -$space-3;
  }
  .lg-pxn4 {
    padding-left: -$space-4;
    padding-right: -$space-4;
  }

  .lg-pl-auto {
    padding-left: auto
  }
  .lg-pr-auto {
    padding-right: auto
  }
  .lg-px-auto {
    padding-left: auto;
    padding-right: auto;
  }

}