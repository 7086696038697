.cms-layout-one {
	.additonal-content {
		margin-top: 40px;
		display: block;
		clear: both;
		overflow: hidden;
	}
	.icon-blocks {
		margin-bottom: 20px;
		display: block;
		clear: both;
		overflow: hidden;
		.icon-block-title {
			font-size: 20px;
			margin-top: 20px;
			margin-bottom: 20px;
			font-weight: 500;
		}		
	}
	.slideshow-container,	
	.custom-banner {
		margin-bottom: 60px;
		display: block;
	}
	.alternative-blocks {
		line-height: 20px;
		font-size: 13px;
		clear: both;
		display: block;
		.block-item {
		margin-bottom: 60px;
		}
	}
} 