.form-column-spacing {

}

.trade-information-customer {
    background: #f3f3f3;
    padding: 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 100%;
    float: left;
    clear: both;
    display: block;
    h4 {
      width: 100%;
      margin: 20px 0;
    }
    form {
      clear: both;

      label {
        display: inline-block;
        max-width: 100%;
        margin-top: 10px;
        font-weight: 200;

        em {
          padding-right: 5px;
          margin: 0;
          color: #ff0000;
          font-size: 12px;
          vertical-align: middle;
        }
      }

      input {
        border: 1px solid #b3b3b3;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        &:focus {
          outline: none;
          box-shadow: none;
          border-color: black;
        }
      }
      textarea {
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        &:focus {
          outline: none;
          box-shadow: none;
          border-color: black;
        }
      }
      select {
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        &:focus {
          outline: none;
          box-shadow: none;
          border-color: black;
        }
      }

      .actions-toolbar {

          .button {
            color: #fff;
            text-transform: uppercase;
            font-weight: 600;
            float: right;
            margin-top: 20px;
            background: #007096;
            transition: 0.4s;
            -webkit-transition: 0.4s;
            -moz-transition: 0.4s;
            text-decoration: none;
            &:hover {
              background: #ff7300;
              text-decoration: none;
            }
            &:focus {
              background: #ff7300;
              text-decoration: none;
            }
            &:active {
              background: #ff7300;
              text-decoration: none;
            }
          }

      }

    }
}