.block-minicart {
    .items-total {
        float: left;
        margin: 0 10px;

        .count {
            font-weight: 700;
        }
    }

    .subtotal {
        margin: 0 10px;
        text-align: right;
        float: right;
        line-height: normal;

        .label {
            margin: 0;
        }
    }

    .product-image-wrapper {
        padding: 0 !important;
        border: 1px solid #eaeaea;
        display: block;
        overflow: hidden;
    }

    .details-qty.qty {
        .label {
            display: none;
        }

        input {
            border: 1px solid #eaeaea;
        }
    }

    .actions {
        display: block;
        overflow: hidden;
        clear: both;
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: 18px;
                    font-weight: 700;
                }
            }
        }
    }

    .block-content {
        & > .actions {
            margin-top: 15px;
			
            .btn-primary {
                display: block;
                clear: both;
                margin-top: 20px;
                margin-bottom: 10px;
                font-size: 12px;
            }

            & > .secondary {
                text-align: center;
            }

            & > .primary {
                margin: 0 10px 15px;

                .action.primary {
                    display: block;
                    width: 100%;
                }
            }

            .paypal-logo {
                margin-top: 15px;
                text-align: center;
            }
        }
    }

    .subtitle {
        display: none;
    }

    .subtitle.empty {
        display: block;
        font-size: 14px;
        padding: 30px 0 20px;
        text-align: center;
    }

    .text.empty {
        text-align: center;
    }

    .block-category-link {
        margin: 15px 0 0;
        text-align: center;
    }

    .block-product-link {
        margin: 15px 0 0;
        text-align: center;
    }

    .block-cms-link {
        margin: 15px 0 0;
        text-align: center;
    }

    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        display: table;
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }

    .action.showcart {
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;
		padding-bottom: 5px;

        .counter.qty.empty {
            display: none;
        }

        .counter.qty {
            .loader {
                & > img {
                    max-width: 24px;
                }
            }
        }
    }

    .action.showcart.active {
        display: inline-block;
        text-decoration: none;

        &:hover {
            &:before {
                color: #333;
            }
        }

        &:active {
            &:before {
                color: #8f8f8f;
            }
        }
    }

    .block-minicart {
        margin: 0;
        padding: 0;
        list-style: none none;
        background: #fff;
        border: 1px solid #bbb;
        margin-top: 4px;
        min-width: 100%;
        z-index: 100;
        box-sizing: border-box;
        display: none;
        top: 100%;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
        padding: 25px 20px;
        right: 0;
        width: 320px;

        li {
            margin: 0;

            &:hover {
                cursor: pointer;
            }
        }

        &:before {
            border-bottom-style: solid;
            content: '';
            display: block;
            height: 0;
            position: absolute;
            width: 0;
            border: 6px solid;
            border-color: transparent transparent #fff transparent;
            z-index: 99;
            left: 10px;
            top: -12px;
            left: auto;
            right: 26px;
        }

        &:after {
            border-bottom-style: solid;
            content: '';
            display: block;
            height: 0;
            position: absolute;
            width: 0;
            border: 7px solid;
            border-color: transparent transparent #bbb transparent;
            z-index: 98;
            left: 9px;
            top: -14px;
            left: auto;
            right: 25px;
        }

        .block-title {
            display: none;
        }
    }

    .product {
        .actions {
            & > .primary {
                display: inline;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }

            & > .secondary {
                display: inline;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }

    .action.close {
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        background-image: none;
        background: none;
        -moz-box-sizing: content-box;
        border: 0;
        box-shadow: none;
        line-height: inherit;
        margin: 0;
        padding: 0;
        text-shadow: none;
        font-weight: 400;
        display: inline-block;
        text-decoration: none;

        &:focus {
            background: none;
            border: none;
        }

        &:active {
            background: none;
            border: none;

            &:before {
                color: inherit;
            }
        }

        &:hover {
            background: none;
            border: none;

            &:before {
                color: inherit;
            }
        }

        & > span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:before {
            -webkit-font-smoothing: antialiased;
            font-size: 16px;
            line-height: 16px;
            color: #8f8f8f;
            content: '\e616';
            font-family: 'luma-icons';
            margin: 0;
            vertical-align: top;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }
    }

    .action.close.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
    }

    .action.close[disabled] {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
    }
}

.minicart-wrapper.active {
    overflow: visible;

    .block-minicart {
        display: block;
        position: absolute;
    }
}

.qty-action-wrapper {
    display: flex;
    align-items: center;

	.qty-wrapper {
		flex-grow: 1;
	}
}

fieldset[disabled] {
    .minicart-wrapper {
        .action.close {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

.minicart-items {
    margin: 0;
    padding: 0;
    list-style: none none;

	&-wrapper {
		border: 1px solid #ccc;
		margin: 0 -20px;
		border-left: 0;
		border-right: 0;
		overflow-x: auto;
		padding: 15px;
	}

    .product-item {
        padding: 20px 0;

        &:not(:first-child) {
            border-top: 1px solid #ccc;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            width: 4.5rem;
            color: #000;
            text-align: left;
            font-weight: normal;
            font-size: 12px;
            padding: 0;
            line-height: 23px;
        }
    }

    .price-minicart {
        margin-bottom: 5px;
    }

    .product-item-name {
        font-weight: 400;
        margin: 0 0 10px;

        a {
            color: #667380;
            font-family: Oswald, sans-serif;
            font-weight: normal;
            font-size: 12px;
            letter-spacing: normal;
        }
    }

    .product-item-details {
        padding-left: 88px;
        text-align: left;
        line-height: normal;

        .price {
            font-weight: 700;

			&-including-tax {
				margin: 5px 0;
			}
	
			&-excluding-tax {
				margin: 5px 0;
			}
        }

        .weee[data-label] {
            font-size: 1.1rem;
        }

        .details-qty {
            margin-top: 10px;
        }
    }

    .product {
        & > .product-item-photo {
            float: left;
            display: flex;
        }

        & > .product-image-container {
            float: left;
        }

        .toggle {
            border: 0;
            padding: 0 40px 5px 0;

            &:after {
                color: #8f8f8f;
                margin: 0 0 0 5px;
                position: static;
            }
        }

        .active {
            >.toggle {
                &:after {
                    content: '\e621';
                }
            }
        }
    }

    .product.pricing {
        margin-top: 3px;
    }

    .product.options {
        .toggle.tooltip {
            display: inline-block;
            text-decoration: none;

            & > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            &:after {
                -webkit-font-smoothing: antialiased;
                font-size: 12px;
                line-height: 12px;
                color: inherit;
                content: '\e622';
                font-family: 'luma-icons';
                margin: -3px 0 0 7px;
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }

        .details {
            display: none;
        }
    }

    .item-qty {
        margin-right: 10px;
        text-align: center;
        width: 80px;
        height: 31px;
        border: 1px solid #eaeaea;
    }

    .update-cart-item {
        vertical-align: top;
        display: block;
        width: 80px;
    }

    .subtitle {
        display: none;
    }

    .action.edit {
        display: inline-block;
        text-decoration: none;

        & > span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:before {
            -webkit-font-smoothing: antialiased;
            font-size: 18px;
            line-height: 20px;
            color: #8f8f8f;
            content: '\f044';
            font-family: 'FontAwesome';
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }

        &:hover {
            &:before {
                color: #333;
            }
        }

        &:active {
            &:before {
                color: #8f8f8f;
            }
        }
    }

    .action.delete {
        display: inline-block;
        text-decoration: none;

        & > span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:before {
            -webkit-font-smoothing: antialiased;
            font-size: 18px;
            line-height: 20px;
            color: #8f8f8f;
            content: '\f014';
            font-family: 'FontAwesome';
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }

        &:hover {
            &:before {
                color: #333;
            }
        }

        &:active {
            &:before {
                color: #8f8f8f;
            }
        }
    }
}

@media all and (max-width: 1200px) {
    .minicart-wrapper .action.showcart {
        padding-bottom: 0px;
    }
}
