//
//  Shopping cart
//  ---------------------------------------------

//  Summary block
.cart-summary {
    background: #f5f5f5;
    margin-bottom: $indent-m;
    padding: 1px 15px $indent-m;

    &>.title {
        display: block;
    }

    .actions-toolbar {
        display: block;
        overflow: hidden;
    }

    .sub .amount {
        border-top: 0;
    }

    #shipping-zip-form legend,
    #shipping-zip-form legend+br {
        display: none;

    }

    .totals .mark {
        background: transparent;
    }

    .sub .mark {
        border-top: 0;
    }

    #block-discount {
        .content {
            display: none;
        }
    }

    .block {
        margin-bottom: 0;

        &.active {
            .title {
                right: 3px;
                color: #858585;
                padding-bottom: 15px;
                width: 95%;

                &::after {
                    @include fa-icon();
                    -webkit-font-smoothing: antialiased;
                    font-size: 12px;
                    line-height: 12px;
                    color: inherit;
                    content: $fa-var-chevron-up;
                    font-family: 'FontAwesome';
                    margin: 3px 0 0;
                    float: right;
                    cursor: pointer;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;

                    strong {
                        font-weight: 100;
                    }
                }
            }
        }

        .title {
            right: 3px;
            color: #858585;
            padding-bottom: 15px;

            &::after {
                @include fa-icon();
                -webkit-font-smoothing: antialiased;
                font-size: 12px;
                line-height: 12px;
                color: inherit;
                content: $fa-var-chevron-down;
                font-family: 'FontAwesome';
                margin: 3px 0 0;
                float: right;
                cursor: pointer;
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                text-align: center;
            }

            strong {
                font-weight: 100;
            }
        }

        .item-options {
            margin-left: 0;
        }

        .fieldset {

            .field {
                margin: 0 0 $indent-s;

                &.note {
                    font-size: $font-size-small;
                }
            }

            .methods {
                .field {
                    &>.label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {

            &>.legend,
            &>.legend+br {}
        }
    }

    .actions-toolbar {
        &>.primary {
            button {}
        }
    }
}

.cart-container {
    .table-caption {
        display: none;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}

.cart.table-wrapper {
    border-top: 1px solid #ccc
}

.cart.table-wrapper .col.price,
.cart.table-wrapper .col.qty {
    text-align: center !important;
}

.cart.table-wrapper .col.qty,
.cart.table-wrapper .col.price,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp {
    box-sizing: border-box;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
}

// .cart.table-wrapper .col.qty:before,
// .cart.table-wrapper .col.price:before,
// .cart.table-wrapper .col.subtotal:before,
// .cart.table-wrapper .col.msrp:before {
//     content: attr(data-th);
//     display: block;
//     font-weight: 600;
//     padding-bottom: 10px
// }

.cart.table-wrapper .item .col.item {
    padding: 13px 8px 13px 0;
}

.cart.table-wrapper .item .col.item {
    display: block;
    min-height: 75px;
    position: relative;
}

.cart.table-wrapper .col.msrp {
    white-space: normal
}

.cart.table-wrapper .item .col.item {
    padding-bottom: 0
}

.cart.table-wrapper tbody>tr>td:last-child {
    border: 0
}

.cart-summary .field input[type="radio"] {
    display: inline-block;
    vertical-align: middle;
    color: #000;
}

.cart-summary .label {
    color: #000;
    font-size: 13px;
    font-family: 'Oswald', sans-serif;
    vertical-align: middle;
    line-height: normal;
    padding-left: 0;
    margin-bottom: 4px;
}

.cart-summary .block .fieldset .methods .field>.label {
    display: inline;
    line-height: 17px;
    padding-top: 9px;
    margin: 0;
}

//  Totals block
.cart-totals {
    border-top: 1px solid #ddd;
    padding-top: $indent-s;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

.product-item-details .item-options dd,
.product-item-details .item-options dt {
    display: inline-block;

}

.product-item-details .item-options dd {
    width: 70%;
}

.product-item-details .item-options dt {
    clear: left;
    width: 25%;
}

.col.rowactions {
    font-size: 20px;
    line-height: 115px;

    .action-delete {
        margin-left: 10px;
        color: #e9e9d9;
    }
}

.col.qty .qty {
    margin-top: 10px;
}

.col.price .price,
.col.subtotal .price {
    line-height: 56px;
}

//  Products table
.cart.table-wrapper {
    .items {
        thead+.item {
            border-top: 1px solid #ddd;
        }

        &>.item {
            border-bottom: 1px solid #ddd;
            position: relative;
        }
    }

    .col {
        padding-top: 10px;

        &.qty {
            .label {
                display: none;
            }

            .input-text {
                text-align: center;
                margin: auto;
            }

            .label {}
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent-s;
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent-m 0 $indent-s 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        &>.action {
            margin-bottom: $indent-s;
            margin-right: $indent-s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            font-weight: 500;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: 600;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        font-size: $font-size-small;
        margin-bottom: 0;
    }

    .product-item-name+.item-options {
        margin-top: $indent-s;
    }

    .product-image-wrapper {}

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent-base;
    }
}

.cart-container {
    .checkout-methods-items {
        margin-top: $indent-base;
        text-align: center;
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        .action.primary {
            width: 100%;
        }

        .item {
            &+.item {
                margin-top: $indent-base;
            }
        }
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    &+.cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {

    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp,
    .amount,
    .col.sku {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________

@media (max-width: 1200px) {
    .cart {
        &.table-wrapper {
            .item {
                .col {
                    &.item {
                        width: 100%;
                        
                        .input-text {
                            display: inline-block;
                            width: 60px;
                            text-align: center;
                        }
                        
                        .mobile-product-details {
                            display: flex;
                            align-items: center;
                            padding: 10px 0px;

                            .price-wrapper,
                            .qty-wrapper,
                            .subtotal-wrapper {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                }

                &-item-details {
                    display: table-cell;
                    padding-left: 80px;
                }
            }
        }

        &.main.actions .btn {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 768px) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: 700;
                        padding-bottom: $indent-s;
                    }
                }

                &.msrp {
                    white-space: normal;
                }

                &.item {
                    .mobile-product-details {
                        justify-content: center;
                        flex-direction: column;
                    }

                    .actions-toolbar {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                    }
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }

            .product {
                &-item-details {
                    padding-left: 0px;
                }
            }
        }
    }

    .cart-container {
        .form-cart {}
    }
}

//
//  Desktop
//  _____________________________________________

@media (min-width: 769px) {
    .cart-container {

        .form-cart {}

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 40%;

        &>.title {
            font-size: 2.4rem;
            font-weight: 300;
            margin: 12px 0;
        }

        .actions-toolbar {
            .column.main & {

                &>.secondary {
                    float: none;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            width: 60%;
            float: left;
            -ms-flex-order: 1;
            -webkit-order: 1;
            order: 1;
            padding-right: 4%;
            position: relative;
        }
    }

    .cart {
        &.table-wrapper {
            .items {
                // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    &.item {
                        padding: 13px 8px 13px;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }
            .item-actions {
                .actions-toolbar {
                    text-align: left;
                }
            }
        }
    }
}

@media (min-width: 1201px) {
    .cart {
        &.table-wrapper {
            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent-base;
                    position: static;
                    vertical-align: top;
                    width: 25%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                }
            }
        }
    }
    
    .cart-summary {
        width: 25%;
    }

    .cart-container {
        .form-cart {
            width: 75%;
        }
    }
}
