.ourprice-label {
    display: none;
}

.catalog-product-view {
    .block.review-add {
        overflow: visible;
    }

    .ourprice-label {
        display: block;
        text-transform: uppercase;
        font-family: 'Oswald', san-serif;
        font-size: 13px;
        font-weight: 400;
    }

    .product.attribute.sku {

        padding-top: 10px;
        margin-top: 18px;
        border-top: 1px solid #eaeaea;
        font-size: 12px;
        font-family: 'Oswald', san-serif;

    }

    @media (min-height: 800px) {
        .product-info-main {
            border: 1px solid #eaeaea;
            min-height: 635px;
            padding: 30px;
        }

        .product.media.col-xs-12.col-md-6 {
            border: 1px solid #eaeaea;
            border-right: none;
            padding: 30px 0;
            min-height: 635px;
        }
    }

    .product-social-links {
        padding-bottom: 30px;

        a {
            margin-right: 20px;

            i {
                vertical-align: middle;
            }
        }
    }

    .action-skip-wrapper {
        display: none;
    }

    .gallery-slider-thumb .slick-slide img {
        display: block;
        margin: auto;
    }

    .page-title-wrapper {
        text-align: left;

        h1.page-title {
            font-size: 34px;
            margin-bottom: 30px;
            font-family: 'Oswald', san-serif;
            color: #333;
            font-weight: 400;
        }
    }

    .product-view-tabs {
        .panel-heading {
            background: none;
            border-radius: 0 !important;
            border-color: #999;
            padding: 15px;
        }

        .panel.panel-default {
            border: none;
            border-bottom-color: currentcolor;
            border-bottom-style: none;
            border-bottom-width: medium;
            border-bottom: 1px solid #ccc;
            border-radius: 0;

        }

        .panel-default:first-child {
            border-top: 1px solid #ccc;
        }

        .panel-heading a {
            width: 100%;
            display: block;
            font-size: 15px;
            font-weight: 700;
            text-decoration: none;

            .rating {
                display: none;
                margin-left: 30px;
                font-size: 18px;
                vertical-align: middle;
            }

            &::focus {
                text-decoration: none;
            }

            &::hover {
                text-decoration: none;
            }

            &::active {
                text-decoration: none;
            }
        }
    }

    .product.attribute.sku strong,
    .product.attribute.sku>div {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        font-weight: normal;
    }

    .product-info-stock-sku {
        margin-bottom: 10px;
    }

    .rating-summary {
        display: none !important;
    }

    .price-final_price .price {
        font-size: 28px;
        font-weight: 700;
        color: #333333;
        font-family: 'Oswald', sans-serif;
    }

    .swatch-attribute-selected-option {
        display: none;
    }

    .swatch-attribute-label,
    .swatch-attribute-options {
        display: inline-block;
        vertical-align: middle;
        min-width: 80px;
        margin-top: 0;
        font-weight: normal;
        font-size: 12px;
        line-height: normal;
        min-height: 40px;
    }

    .swatch-attribute-label {
        line-height: 40px;
    }

    .swatch-option {
        height: 40px;
        width: 40px;
        margin-top: 0;
        margin-bottom: 0;
    }

    #product-addtocart-button {
        padding: 10px 80px;
        border: 0;
        border-radius: 5px;
        font-size: 20px;
        font-weight: 700;
    }

    .box-tocart {
        margin-top: 50px;
        display: block;
        //overflow: hidden;
        margin-bottom: 0px;

        .actions,
        .field.qty {
            display: inline-table;
            vertical-align: top;
            margin-bottom: 10px;

            label {
                display: none;
            }
        }

        #qty {
            display: inline-block;
            width: auto;
            min-width: auto;
            max-width: 100px;
            height: 48px;
            vertical-align: top;
            margin-right: 0px;
            text-align: center;
        }

        #qty-error {
            position: absolute;
            top: -35px;
            z-index: 1000;
            width: 200px;
            left: 0;
        }
    }

    .product-item-name .grid-wish-compare {
        display: inline-block;
        float: right;
        font-size: 24px;
        line-height: 1;
        vertical-align: middle;

        .towishlist:hover {
            i {
                color: #FF0000 !important;
            }
        }

        .tocompare:hover {
            i {
                color: #000033 !important;
            }
        }
    }

    .products-grid {
        .row {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-wrap: wrap;
        }

        .row>[class*='col-'] {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
        }

        .product-item-photo {
            display: block;
            overflow: hidden;
            margin-bottom: 10px;
        }

        .price-box {
            display: block;
            font-weight: 600;
            margin-top: 5px;
        }

        .swatch-option {
            width: 20px;
            min-width: auto;
            margin: 0 5px 5px 0;
        }
    }

    #product-related-cross-tabs {
        .active {
            a {
                border-radius: 0;
            }
        }

        .tab-content {
            padding-top: 30px;
        }

        .price-box {
            font-size: 14px;
        }
    }

    .review-control-vote input[type="radio"] {
        display: none;
    }

    .review-control-vote label {
        display: inline-block;
        vertical-align: top;
        font-size: 17px;

        &:hover {}
    }

    .legend.review-legend {
        display: none;
    }
}

.catalog-product-compare-index {

    .productReviews,
    .product-item-name,
    .price-box {
        margin-top: 10px;
        display: block;
        margin-bottom: 10px;

    }

    .material-icons {
        vertical-align: bottom;
    }

    td.info {
        background: #fff !important;
    }

    .cell {
        border: none !important;
        margin: 0;
    }

    .attribute.label {
        vertical-align: top;
        padding: 0;
        margin: 0;
        line-height: normal;
        height: auto;
        display: block;
    }
}

.rating-summary {
    overflow: hidden;
    white-space: nowrap;
}

.rating-summary .rating-result {
    width: 88px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.rating-summary.empty .rating-result>span::before {
    color: #c7c7c7;
}

.rating-summary .rating-result:before {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
    color: #c7c7c7;
    font-family: 'luma-icons';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    content: '\e605''\e605''\e605''\e605''\e605';
    display: block;
    font-style: normal;
    font-weight: normal;
    speak: none;
}

.rating-summary .rating-result>span {
    display: block;
    overflow: hidden;
}

.rating-summary .rating-result>span:before {
    position: relative;
    z-index: 2;
    -webkit-font-smoothing: antialiased;
    color: #ffd400;
    font-family: 'luma-icons';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    content: '\e605''\e605''\e605''\e605''\e605';
    display: block;
    font-style: normal;
    font-weight: normal;
    speak: none;
}

.rating-summary .rating-result>span span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.review-control-vote {
    overflow: hidden;
}

.review-control-vote:before {
    color: #c7c7c7;
    font-family: 'luma-icons';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    content: '\e605''\e605''\e605''\e605''\e605';
    display: block;
    position: absolute;
    z-index: 1;
}

.review-control-vote input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.review-control-vote input[type="radio"]:focus+label:before,
.review-control-vote input[type="radio"]:checked+label:before {
    opacity: 1;
}

.review-control-vote label {
    cursor: pointer;
    display: block;
    position: absolute;
}

.review-control-vote label span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.review-control-vote label:before {
    color: #ffd400;
    font-family: 'luma-icons';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    opacity: 0;
}

.review-control-vote label:hover:before {
    opacity: 1;
}

.review-control-vote label:hover~label:before {
    opacity: 0;
}

.review-control-vote .rating-5 {
    z-index: 2;
}

.review-control-vote .rating-5:before {
    content: '\e605''\e605''\e605''\e605''\e605';
}

.review-control-vote .rating-4 {
    z-index: 3;
}

.review-control-vote .rating-4:before {
    content: '\e605''\e605''\e605''\e605';
}

.review-control-vote .rating-3 {
    z-index: 4;
}

.review-control-vote .rating-3:before {
    content: '\e605''\e605''\e605';
}

.review-control-vote .rating-2 {
    z-index: 5;
}

.review-control-vote .rating-2:before {
    content: '\e605''\e605';
}

.review-control-vote .rating-1 {
    z-index: 6;
}

.review-control-vote .rating-1:before {
    content: '\e605';
}

.review-control-vote:before {
    content: '\e625''\e625''\e625''\e625''\e625';
}

.review-toolbar {
    border-top: 1px solid #c9c9c9;
    margin: 0 0 20px;
    padding: 0;
    text-align: center;
}

.review-toolbar:first-child {
    display: none;
}

.review-toolbar .pages {
    border-bottom: 1px solid #c9c9c9;
    display: block;
    padding: 20px 0;
    text-align: center;
}

.review-toolbar .limiter {
    display: none;
}

.review-add .block-title {
    display: none;
}

.review-form .action.submit.primary {
    background: #eeeeee;
    border: 1px solid #cccccc;
    color: #333333;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 7px 15px;
    margin: 0;
}

.review-form .action.submit.primary:focus,
.review-form .action.submit.primary:active {
    background: #e2e2e2;
    border: 1px solid #cccccc;
    color: #333333;
}

.review-form .action.submit.primary:hover {
    background: #e1e1e1;
    border: 1px solid #cccccc;
    color: #333333;
}

.review-form .actions-toolbar .actions-primary {
    padding: 0;
}

.fieldset .review-legend.legend {
    border-bottom: 0;
    line-height: 1.3;
    padding: 0;
    margin-bottom: 20px;
}

.fieldset .review-legend.legend span {
    margin: 0;
}

.fieldset .review-legend.legend strong {
    display: block;
    font-weight: 600;
}

.fieldset .review-field-ratings>.label {
    font-weight: 600;
    margin-bottom: 10px;
    padding: 0;
}

.review-control-vote label:before,
.review-control-vote:before {
    font-size: 32px;
    height: 32px;
    letter-spacing: 10px;
    line-height: 32px;
}

.review-field-rating .label {
    font-weight: 600;
}

.review-field-rating .control {
    margin-bottom: 48px;
    margin-top: 10px;
}

.review-list {
    margin-bottom: 30px;
}

.review-list .block-title strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}

.review-item {
    border-bottom: 1px solid #c9c9c9;
    margin: 0;
    padding: 20px 0;
}

.review-item:after {
    clear: both;
    content: '';
    display: table;
}

.review-item:last-child {
    border-width: 0;
}

.review-ratings {
    display: table;
    margin-bottom: 10px;
    max-width: 100%;
}

.review-author {
    display: inline;
}

.review-author .review-details-value {
    font-weight: 400;
}

.review-date {
    display: inline;
}

.review-title {
    font-weight: 600;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin: 0 0 20px;
}

.review-content {
    margin-bottom: 20px;
}

.page-main .column .review-add {
    margin-bottom: 0;
}

.data.table.reviews .rating-summary {
    margin-top: -4px;
}

.customer.review.view .review.title,
.customer.review.view .ratings.summary.items,
.customer.review.view .product.details {
    margin-bottom: 20px;
}

.product-reviews-summary {
    margin-bottom: 5px;
}

.products.wrapper.list .product-reviews-summary {
    margin: 0 auto;
}

.product-reviews-summary .rating-summary {
    display: inline-block;
    vertical-align: middle;
}

.product-reviews-summary .reviews-actions {
    display: inline-block;
    font-size: 11px;
    vertical-align: middle;
}

.product-reviews-summary .reviews-actions a:not(:last-child) {
    margin-right: 30px;
}

.product-info-main .rating-summary,
.customer-review .rating-summary {
    margin-right: 30px;
}

.block-reviews-dashboard .items .item {
    margin-bottom: 20px;
}

.block-reviews-dashboard .items .item:last-child {
    margin-bottom: 0;
}

.block-reviews-dashboard .product-name {
    display: inline-block;
}

.block-reviews-dashboard .product-name:not(:last-child) {
    margin-bottom: 5px;
}

.block-reviews-dashboard .rating-summary .label {
    font-weight: 600;
    margin-right: 10px;
}

.table-reviews .product-name,
.block-reviews-dashboard .product-name {
    font-weight: 400;
}

.table-reviews .rating-summary {
    display: inline-block;
    vertical-align: baseline;
}

.customer-review .product-media {
    float: left;
    margin-right: 3%;
    max-width: 285px;
    width: 30%;
}

.customer-review .review-details {
    margin: 0;
}

.customer-review .review-details .customer-review-rating {
    margin-bottom: 20px;
}

.customer-review .review-details .customer-review-rating .item {
    margin-bottom: 10px;
}

.customer-review .review-details .customer-review-rating .item:last-child {
    margin-bottom: 0;
}

.customer-review .review-details .review-title {
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.customer-review .review-details .review-content {
    margin: 0;
    margin-bottom: 20px;
}

.customer-review .review-details .review-date {
    color: #a6a6a6;
}

.block-reward-info .reward-balance {
    font-size: 1.8rem;
    margin-bottom: 25px;
}

.block-reward-info .reward-rates:not(:last-child),
.block-reward-info .reward-limit:not(:last-child),
.block-reward-info .reward-expiring:not(:last-child) {
    margin-bottom: 25px;
}

.block-reward-info .reward-rates .title,
.block-reward-info .reward-limit .title,
.block-reward-info .reward-expiring .title {
    display: inline-block;
    margin-bottom: 10px;
}

.product-items {
    .price-box {
        font-size: 20px;
        font-family: 'Oswald', san-serif;
        color: #333333;
    }

    .product-item-link {
        color: #000;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .rating {
        span {
            color: #ffd400;
        }
    }

    .costsaving {
        color: #fb3957;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
    }

    .rrp {
        font-weight: 400;
        color: #333;
        font-size: 14px;
    }

    .rrp label {
        font-weight: inherit;
    }

    .case-label {
        color: #333;
        font-size: 13px;
        font-style: italic;
    }
}

.product-info-main {
    .product-info-price>.product.attribute.overview {

        .type,
        .value {
            display: inline;
        }

        &.qty {
            p {
                margin-bottom: 0;
            }
        }
    }

    .costsaving {
        color: #fb3957;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        font-family: Oswald, sans-serif;
    }

    .rrp {
        font-weight: 400;
        color: #333;
        font-size: 14px;
        font-family: Oswald, sans-serif;
    }

    .rrp label {
        font-weight: inherit;
        font-family: Oswald, sans-serif;
    }

    //.stock-status-container,
    .price-box,
    .rating-summary,
    .product-reviews-summary {
        display: inline-block;
        width: 48%;
        margin: 0 !important;
        vertical-align: top;
    }

    .product-reviews-summary,
    .rating-summary {
        text-align: right;
    }

    .towishlist {
        display: inline-block;
        float: right;
        margin-left: 10px;

        &:hover {
            .fa {
                color: #FF0000 !important;
            }
        }
    }
}

.catalog-product-view {
    .block {
        display: block;
        clear: both;
        overflow: hidden;

        .block-title {
            font-size: 24px;
            font-family: Oswald, sans-serif;
            color: #333333;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 20px;
            margin-top: 40px;
            display: block;
            overflow: hidden;
            clear: both;
            text-transform: uppercase;
        }
    }

    #customer-reviews {
        padding-left: 15px;
        padding-right: 15px;

        .block-content {

            padding-left: 15px;
            padding-right: 15px;

        }
    }

    .items.review-items {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .review-items {
        font-family: Oswald, sans-serif;
    }

    .label.rating-label {
        margin-bottom: 0;
    }

    .btn-number {
        height: 48px;
        display: block;
    }

    .btn-bordered {
        border: 1px solid;
        padding: 7px 20px;
        font-size: 11px;
        font-family: 'Oswald', san-serif;
        display: table;

        i {
            display: inline-block;
            font-size: 23px;
        }

        span {
            display: inline-block;
            font-weight: normal;
            text-align: left;
            line-height: 12px;
            vertical-align: middle;
            margin-left: 9px;
        }
    }
}
